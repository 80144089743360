import React, { useState, useEffect, useRef } from 'react';

const SituationPopup = ({ 
  isOpen, 
  onClose, 
  onProceed,
  name,
  partner_name,
  relationship,
  topic,
  u1_Pstatus,
  u2_Pstatus,
  relation,
  belief_system
}) => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState(null);
  const [isAdviceReached, setIsAdviceReached] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setMessages([]);
      setCurrentMessage('');
      setChatHistory(null);
      setIsAdviceReached(false);
      initiateChat();
    }
  }, [isOpen]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const initiateChat = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://lab-api.aris.ai/ARIS/relationship/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name, 
          partner_name,
          relation,
          relationship,
          topic,
          u1_Pstatus,
          u2_Pstatus,
          belief_system,
          stream: true,
          query: '',
          history: []
        })
      });

      const data = await response.json();
      
      setMessages(prev => [...prev, {
        type: 'bot',
        content: data.response.question
      }]);
      
      setChatHistory(data.history);
      setIsAdviceReached(data.response.is_next_conversation_is_advice);

      if (data.response.is_next_conversation_is_advice) {
        // Add 2-second delay before proceeding when advice is reached
        setTimeout(() => {
          onProceed(data.history);
          onClose();
        }, 2000);
      }
    } catch (error) {
      console.error('Error initiating chat:', error);
      setMessages(prev => [...prev, {
        type: 'error',
        content: 'Failed to start the conversation. Please try again.'
      }]);
    }
    setIsLoading(false);
  };

  const sendMessage = async (userMessage) => {
    setIsLoading(true);
    try {
      const response = await fetch('https://lab-api.aris.ai/ARIS/relationship/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name, 
          partner_name,
          relation,
          relationship,
          topic,
          u1_Pstatus,
          u2_Pstatus,
          belief_system,
          stream: true,
          query: currentMessage,
          history: chatHistory
        })
      });

      const data = await response.json();
      
      setMessages(prev => [...prev, {
        type: 'bot',
        content: data.response.question
      }]);
      
      setChatHistory(data.history);
      setIsAdviceReached(data.response.is_next_conversation_is_advice);

      if (data.response.is_next_conversation_is_advice) {
        // Add 2-second delay before proceeding when advice is reached
        setTimeout(() => {
          onProceed(data.history);
          onClose();
        }, 2000);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, {
        type: 'error',
        content: 'Failed to send message. Please try again.'
      }]);
    }
    setIsLoading(false);
  };


  const handleSend = () => {
    if (currentMessage.trim() && !isLoading) {
      const userMessage = currentMessage.trim();
      setMessages(prev => [...prev, {
        type: 'user',
        content: userMessage
      }]);
      setCurrentMessage('');
      sendMessage(userMessage);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };
  const getMessageWidth = (content) => {
    const baseWidth = 40; // Minimum width percentage
    const charLength = content.length;
    const widthPerChar = 0.5; // Width percentage to add per character
    const calculatedWidth = Math.min(baseWidth + charLength * widthPerChar, 80); // Cap at 80%
    return `${calculatedWidth}%`;
  };

  const messageStyle = (type, content) => ({
    margin: '8px 0',
    padding: '12px 16px',
    borderRadius: type === 'user' ? '20px 20px 0 20px' : '20px 20px 20px 0',
    maxWidth: type === 'user' ? getMessageWidth(content) : '80%',
    minWidth: type === 'user' ? '120px' : '200px',
    wordWrap: 'break-word',
    backgroundColor: type === 'user' ? '#5bacb9' : 'black', // Changed bot message color to purple
    color: 'white',
    marginLeft: type === 'user' ? 'auto' : '0',
    marginRight: type === 'user' ? '0' : 'auto',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    transition: 'all 0.3s ease',
    fontSize: '15px',
    lineHeight: '1.4'
  });

  const popupStyle = {
    display: isOpen ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

  const contentStyle = {
    backgroundColor: '#1a1a1a',
    padding: '30px',
    borderRadius: '15px',
    maxWidth: '500px',
    width: '90%',
    color: 'white',
    position: 'relative',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: '#5bacb9',
    fontSize: '24px',
    cursor: 'pointer',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    transition: 'transform 0.2s ease',
    ':hover': {
      transform: 'scale(1.1)'
    }
  };

  const buttonStyle = {
    padding: '10px 25px',
    margin: '0 10px',
    border: 'none',
    borderRadius: '20px',
    backgroundColor: '#5bacb9',
    color: 'white',
    cursor: 'pointer',
    opacity: isLoading ? 0.7 : 1,
    pointerEvents: isLoading ? 'none' : 'auto',
    transition: 'all 0.2s ease',
    fontWeight: '500',
    ':hover': {
      backgroundColor: '#4a9ba8'
    }
  };

  const chatContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '400px',
    marginTop: '20px'
  };

  const messagesContainerStyle = {
    flex: 1,
    overflowY: 'auto',
    marginBottom: '20px',
    padding: '15px',
    backgroundColor: '#2a2a2a',
    borderRadius: '10px',
    scrollBehavior: 'smooth'
  };

  const inputContainerStyle = {
    display: 'flex',
    gap: '10px',
    position: 'relative'
  };

  const chatInputStyle = {
    flex: 1,
    padding: '12px 15px',
    backgroundColor: '#2a2a2a',
    border: '2px solid #5bacb9',
    borderRadius: '25px',
    color: 'white',
    fontSize: '15px',
    transition: 'border-color 0.3s ease',
    ':focus': {
      borderColor: '#4a9ba8',
      outline: 'none'
    }
  };

  return (
    <div style={popupStyle}>
      <div style={contentStyle}>
        <button 
          style={closeButtonStyle}
          onClick={onClose}
          aria-label="Close"
        >
          ×
        </button>
        <h2 style={{ marginBottom: '20px', textAlign: 'center', fontSize: '24px' }}>Chat with ARIS</h2>
        <div style={chatContainerStyle}>
          <div style={messagesContainerStyle}>
            {messages.map((msg, index) => (
              <div
                key={index}
                style={messageStyle(msg.type, msg.content)}
              >
                {msg.content}
              </div>
            ))}
            {isLoading && (
              <div style={messageStyle('bot', 'Loading...')}>
                Loading...
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div style={inputContainerStyle}>
            <input
              style={chatInputStyle}
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your message here..."
              disabled={isLoading}
            />
            <button 
              style={buttonStyle} 
              onClick={handleSend}
              disabled={isLoading}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SituationPopup;

