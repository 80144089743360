import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Login.css';
import logo from './assests/aris-logo-cut.png';
import gif from './assests/SVKl.gif';
import { useUser } from './UserContext.js';
import { getInstructions } from "./utils/conversation_config.js";

const Login = () => {            
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser, setIsAuthenticated ,setPromptInstruction} = useUser();
  
  

  const validUsers = [ 'Yanni', 'Vira', 'Art', 'Adam', 'Paul', 'Sean', 'Russ', 'Demetra', 'Andy', 'Ambarish', 'Kyle','Siva','Christa','Donald','Emma','Nicholas'];

  const handleLogin =async (e) => {
    e.preventDefault();
    
    let loggedInUser = '';
    if (validUsers.includes(username) && username === password) {
      loggedInUser = username;
    } else if (username === '' && validUsers.includes(password)) {
      loggedInUser = password;
    }else if(username === 'Niko' && password ==='@Ris!2024'){
      loggedInUser=username;
    }

    if (loggedInUser) {
      // Store the logged-in user in localStorage or in a global state management solution
      const instruction= await getInstructions(loggedInUser)
      setPromptInstruction(instruction)
      setUser(loggedInUser);
      setIsAuthenticated(true);
      navigate('/homepage');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className='logo-login' >
          <img src={logo} alt="Description" style={{ marginRight: '10px', width: '135px', height: '60px' }} />
          
        </div>
        <h1 className='description' >
        Welcome to Aris, your AI life coach—your trusted partner, providing personalized guidance for every relationship and challenge. From dating and marriage to career growth and peak performance, Aris empowers you to create a life of harmony, fulfillment, and joy.
        </h1>
        <img className='login-img' src={gif} alt="Login animation" />
      </div>
      
      <div className="login-right">
        <form className="form-container" onSubmit={handleLogin}>
          <div className="form-group">
            <h1 style={{fontSize:'40px',marginLeft:'35%',color:'black'}}>Login</h1>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button className='loginButton' type="submit">LOGIN</button>
        </form>
      </div>
    </div>
  );
};

export default Login;