import React, { useState, useRef, useEffect } from 'react';
import { useUser } from './UserContext.js';

const PronunciationCustomizer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [userAudioUrl, setUserAudioUrl] = useState('');
  const [apiResponseAudioUrl, setApiResponseAudioUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [isVerify, setIsVerify] = useState(false);

  
  const userAudioRef = useRef(null);
  const apiAudioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const { user } = useUser();

  // Styling remains the same as in the previous component
  const popupOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: isOpen ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

  const popupContentStyle = {
    position: 'relative',
    backgroundColor: 'black',
    padding: '20px',
    borderRadius: '10px',
    border: '1px solid #6cb4c2',
    width: '400px',
    maxWidth: '90%'
  };

  const buttonStyle = {
    backgroundColor: 'black',
    color: '#6cb4c2',
    border: '1.2px solid #6cb4c2',
    borderRadius: '5px',
    padding: '8px 15px',
    cursor: 'pointer',
    marginRight: '10px'
  };

  const inputStyle = {
    backgroundColor: 'black',
    color: '#6cb4c2',
    border: '1px solid #6cb4c2',
    borderRadius: '5px',
    padding: '8px',
    width: '200px',
    marginRight: '10px'
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
    color: 'white',
    fontWeight: '500'
  };

  // Initialize audio recording
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setRecordedBlob(audioBlob);
        setUserAudioUrl(audioUrl);
        setIsSaveVisible(true);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  // Stop recording
  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Send recorded audio to API
  const handleSaveRecording = async () => {
    if (!recordedBlob || !inputText) return;
  
    setIsLoading(true);
    try {
      // Construct query string with parameters
      const queryParams = new URLSearchParams({
        user: user,
        Name: inputText
      }).toString();
  
      // Create FormData and append the audio file
      const formData = new FormData();
      formData.append('file', recordedBlob);
  
      const response = await fetch(`https://lab-api.aris.ai/ARIS/generate_audio?${queryParams}`, {
        method: 'POST',
        body: formData
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setApiResponseAudioUrl(url);
      } else {
        console.error('Failed to save recording');
        // Optionally, log the error response
        const errorResponse = await response.text();
        console.error('Error response:', errorResponse);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setIsLoading(false);
  };

  // Play user-recorded audio
  const handlePlayUserAudio = () => {
    if (userAudioRef.current) {
      userAudioRef.current.play();
      setIsVerify(true);
    }
  };

  // Play API response audio
  const handlePlayApiAudio = () => {
    if (apiAudioRef.current) {
      apiAudioRef.current.play();
    }
  };

  // Reset component state
  const resetState = () => {
    setInputText('');
    setUserAudioUrl('');
    setApiResponseAudioUrl('');
    setRecordedBlob(null);
    setIsRecording(false);
    setIsSaveVisible(false);
    audioChunksRef.current = [];
  };

  const handleClose = () => {
    setIsOpen(false);
    resetState();
  };

  const handleGoBack = () => {
    // Reset only the API response-related states
    setInputText('');
    setUserAudioUrl('');
    setApiResponseAudioUrl('');
    setRecordedBlob(null);
    setIsRecording(false);
    setIsSaveVisible(false);
    audioChunksRef.current = [];
  };

  return (
    <>
    <div style={{ position: 'absolute', top: '23px', right: '200px' }}>
      <button onClick={() => setIsOpen(true)} style={buttonStyle}>
        Customize Pronunciation
      </button>
    </div>

    <div style={popupOverlayStyle}>
      <div style={popupContentStyle}>
        <button onClick={handleClose} style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'transparent',
          border: 'none',
          color: '#6cb4c2',
          cursor: 'pointer',
          fontSize: '20px'
        }}>
          ×
        </button>

        <h3 style={{ color: '#6cb4c2', marginBottom: '20px' }}>
          Customize Pronunciation
        </h3>
        {!apiResponseAudioUrl && (

          <>
        <div style={{ marginBottom: '16px' }}>
          <label style={labelStyle}>Enter the person's name</label>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Enter name..."
            style={inputStyle}
            required
          />
        </div>

        <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
          <label style={{
            ...labelStyle,
            verticalAlign: 'middle',
            marginRight: '10px'
          }}>
            Record your name as "Hi say my name as (your name)"
          </label>
          <button 
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onTouchStart={startRecording}
            onTouchEnd={stopRecording}
            style={{
              ...buttonStyle,
              backgroundColor: isRecording ? 'red' : 'black',
              color: isRecording ? 'white' : '#6cb4c2'
            }}
          >
            {isRecording ? 'Recording...' : 'Press and Hold to Record'}
          </button>
        </div>
        </>
        )}

        {userAudioUrl && !apiResponseAudioUrl && (
          <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
            <button onClick={handlePlayUserAudio} style={buttonStyle}>
              Play User Recording
            </button>
            <audio ref={userAudioRef} src={userAudioUrl} style={{ display: 'none' }} />

            {isSaveVisible && isVerify &&(
              <button 
                onClick={handleSaveRecording} 
                disabled={isLoading || !inputText}
                style={buttonStyle}
              >
                {isLoading ? 'Verifying...' : 'Verify with bot'}
              </button>
            )}
          </div>
        )}

        {apiResponseAudioUrl && (
          <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
                 <button onClick={handleGoBack} style={buttonStyle}>
              Back
            </button>
            <button onClick={handlePlayApiAudio} style={buttonStyle}>
              Play bot Response
            </button>
            <audio ref={apiAudioRef} src={apiResponseAudioUrl} style={{ display: 'none' }} />
            <button onClick={handleClose} style={buttonStyle}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  </>
  );
};

export default PronunciationCustomizer;