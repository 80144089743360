import React, { useEffect, useRef, useCallback, useState } from "react";
import { RealtimeClient } from "@openai/realtime-api-beta";
import { WavRecorder, WavStreamPlayer } from "./lib/wavtools/index.js";
import { getInstructions } from "./utils/conversation_config.js";
import { WavRenderer } from "./utils/wav_renderer.ts";
import { X, Mic, PhoneOff } from "react-feather";
import staticImage from './assests/aris-gif-pic.png';
import gifImage from './assests/SVKl.gif';
import { useUser } from './UserContext.js';
import arisLogo from './assests/aris-logo-cut.png';
import './css/speech.css';
import { position } from "@chakra-ui/react";
import voiceImg from './assests/voice.png';
import { useNavigate } from 'react-router-dom';
import { createClient } from "@deepgram/sdk";



const SpeechRecognitionAppDeep = ({ onLogout }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isConnected, setIsConnected] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState('alloy');
  const [transcript, setTranscript] = useState('');
  const [relationships, setRelationships] = useState([]);
  const [chatHistoryList, setChatHistoryList] = useState([{"role":"system","content":`Hi ${user}, I am Aris your personal life coach how can I assist you today?`}]);

 
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const streamRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const transcriptBufferRef = useRef('');
  const lastSpeechTimestampRef = useRef(Date.now());
  const SILENCE_THRESHOLD = 2000; 
  const DEEPGRAM_API_KEY = process.env.REACT_APP_DEEPGRAM_API_KEY;
  const deepgramClientRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);


  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);


  const fetchRelationships = async (userName) => {
    try {
      const response = await fetch(`https://skhcykhlbiyfk3jgydczwa34p40wayiz.lambda-url.us-east-1.on.aws/ARIS/relationship?name=${encodeURIComponent(userName)}&type=List`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
    //   const lowercaseData = data.map(item => item.toLowerCase());
    const relationsList = Object.keys(data.relationship);
    
      setRelationships(relationsList);
     
    } catch (error) {
      console.error("Error fetching relationships:", error);
      setRelationships([]);
      return [];
    }
  };
  
  useEffect(() => {
    fetchRelationships(user);
  }, [user]);



  const sendTextToChatbotApi = async (text) => {
    try {
        const updatedChatHistory = [
            ...chatHistoryList,
            { role: "user", content: text }
          ];
          console.log("🐼",chatHistoryList);
      const url = 'https://skhcykhlbiyfk3jgydczwa34p40wayiz.lambda-url.us-east-1.on.aws/ARIS/new_chat';
      const requestBody = {
        query: text,
        name: user,
        chat_history:chatHistoryList,
        relations: relationships||[]
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

    
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

   
  
  
      const reader = response.body.getReader();
      let completeResponse = '';
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = new TextDecoder().decode(value);
        completeResponse += chunk;
        console.log("Received chunk:", chunk);
      }
      setChatHistoryList(prevChatHistory => [
        ...prevChatHistory,
        { role: "user", content: text },
        { role: "system", content: completeResponse }
      ]);
  console.log("🥶",chatHistoryList,"🥶");
      // Only proceed with text-to-speech if we're not currently listening to user
      if (!isListeningRef.current) {
        await streamTextToSpeech(completeResponse);
      }
  
      return completeResponse;
    } catch (error) {
      console.error('Error in sendTextToChatbotApi:', error);
      throw error;
    }
  };
  const isPlayingAudioRef = useRef(false);
const currentAudioRef = useRef(null);
const isListeningRef = useRef(false);

const streamTextToSpeech = async (text) => {
    try {
      // If we're already listening to user input, don't play the audio
      if (isListeningRef.current) {
        return;
      }
  
      const response = await fetch('http://localhost:3001/text-to-speech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
  
      // Store the audio element reference
      currentAudioRef.current = audio;
      
      // Set up audio event handlers
      audio.onplay = () => {
        isPlayingAudioRef.current = true;
      };
  
      audio.onended = () => {
        isPlayingAudioRef.current = false;
        URL.revokeObjectURL(audioUrl);
        currentAudioRef.current = null;
      };
  
      await audio.play();
    } catch (error) {
      console.error("Error in text-to-speech:", error);
      isPlayingAudioRef.current = false;
      currentAudioRef.current = null;
    }
  };
  
  const streamToArrayBuffer = async (stream) => {
    const reader = stream.getReader();
    const chunks = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
    }
    return new Blob(chunks).arrayBuffer();
  };
  
const handleSpeechDetection = () => {
    // If audio is currently playing, stop it
    if (isPlayingAudioRef.current && currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
      isPlayingAudioRef.current = false;
      
      // Clear any pending transcripts
      transcriptBufferRef.current = '';
    }
  };
  
  // Modify the socket.onmessage handler

  
  
  const handleSilenceDetection = async () => {
    // Clear any existing timeout
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }

    // Set new timeout
    silenceTimeoutRef.current = setTimeout(async () => {
      // Only process if we have some text in the buffer
      if (transcriptBufferRef.current.trim()) {
        console.log("Silence detected, sending transcript:", transcriptBufferRef.current);
        
        try {
            isListeningRef.current = false;
          const response = await sendTextToChatbotApi(transcriptBufferRef.current.trim());
          console.log("Complete transcript processed:", transcriptBufferRef.current);
          console.log("API Response:", response);
          
          // Clear the buffer after successful processing
          transcriptBufferRef.current = '';
        } catch (error) {
          console.error("Failed to process transcript:", error);
        }
      }
    }, SILENCE_THRESHOLD);
  };

  const connectConversation = async () => {
    try {
      // Check if API key exists
      if (!DEEPGRAM_API_KEY) {
        console.error('Deepgram API key not found in environment variables');
        alert('Missing API key configuration');
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      if (!MediaRecorder.isTypeSupported('audio/webm')) {
        alert('Browser not supported');
        return;
      }

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm',
      });
      mediaRecorderRef.current = mediaRecorder;

      const socket = new WebSocket('wss://api.deepgram.com/v1/listen', [
        'token',
        DEEPGRAM_API_KEY,
      ]);

      socket.onopen = () => {
        setIsConnected(true);
        console.log('WebSocket Connection Established');

        mediaRecorder.addEventListener('dataavailable', async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
          }
        });

        mediaRecorder.start(1000);
      };


      socket.onmessage = async (message) => {
        const received = JSON.parse(message.data);
        const transcriptText = received.channel.alternatives[0].transcript;
        
        if (transcriptText && received.is_final) {
          if (isPlayingAudioRef.current) {
            handleSpeechDetection();
          }

          lastSpeechTimestampRef.current = Date.now();
          isListeningRef.current = true;
          
          transcriptBufferRef.current += ' ' + transcriptText;
          setTranscript(prev => prev + ' ' + transcriptText);
          setChatHistoryList(prevChatHistory => [
            ...prevChatHistory,
            { role: "user", content: transcriptText }
          ]);
          
          handleSilenceDetection();
        }
      };


      socket.onclose = () => {
        console.log('WebSocket Connection Closed');
        disconnectConversation();
      };

      socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        disconnectConversation();
      };

      socketRef.current = socket;
    } catch (error) {
      console.error('Error setting up media devices:', error);
      alert('Error accessing microphone. Please ensure microphone permissions are granted.');
    }
  };

  const disconnectConversation = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  
    if (socketRef.current) {
      socketRef.current.close();
    }
  
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
  
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  
    // Stop any playing audio
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current = null;
    }
  
    isPlayingAudioRef.current = false;
    isListeningRef.current = false;
    setIsConnected(false);
    transcriptBufferRef.current = '';
  };
  const handleVoiceChange = (e) => {
    setSelectedVoice(e.target.value);
  };
  useEffect(() => {
    return () => {
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
    };
  }, []);


 console.log(transcript);
//"3333matching",matchingWords,"3333333");

  return   (
    <div className="page-container" style={{ position: 'relative', minHeight: '100vh' }}>
    <div className="content-container" style={{ paddingTop: '20px' }}>
      {/* Back button */}
      <div style={{ position: 'absolute', top: '23px', left: '25px'}}>
        <button
          size={30} 
          color="#6cb4c2" 
          onClick={() => navigate('/homepage')} 
          style={{ cursor: 'pointer' ,width:'50px',height:'27px',backgroundColor:'black',color:'#69b3c1',border:'1.2px solid #69b3c1',borderRadius:'5px'}}
        >Back</button>
      </div>

      {/* Voice selection dropdown */}
     

      {/* Logo and title */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <img 
          src={arisLogo} 
          width="70px" 
          height="70px" 
          alt="Aris Logo" 
          style={{ marginRight: '10px', cursor: 'pointer' }} 
          onClick={() => navigate('/homepage')} 
        />
        <h1 style={{ margin: 0, cursor: 'pointer' }} onClick={() => navigate('/homepage')}>
          Aris Voice
        </h1>
      </div>

      {/* Status image */}
      <img
        src={isConnected ? gifImage : staticImage}
        alt="App status"
        className="status-image"
        onClick={isConnected ? disconnectConversation : connectConversation}
        style={{ display: 'block', margin: '0 auto', cursor: 'pointer' }}
      />
      <h4 style={{ color: 'white', textAlign: 'center', marginTop: '30px' }}>
        {isConnected ? "Tap to stop" : "Tap to speak"}
      </h4>
    </div>

    {transcript && (
          <div className="mt-8 p-4 mx-auto max-w-2xl bg-gray-800/50 rounded-lg">
            <p className="text-white">{transcript}</p>
          </div>
        )}

    {/* Background circles */}
    <ul className="circles" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', margin: 0, padding: 0 }}>
      {[...Array(10)].map((_, index) => (
        <li key={index}></li>
      ))}
    </ul>
  </div>
  );
};

export default SpeechRecognitionAppDeep;