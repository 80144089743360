import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import BeliefSystem from './BeliefSystem.js';

const ArisDailyTopic = () => {
  // Topics for each category
  const topicCategories = {
    Mindset: [
      'Growth', 'Resilience', 'Game-Ready', 'Presentation', 'Speech', 
      'Focus', 'Positivity', 'Negotiation', 'Adaptability', 'Creativity', 
      'Strategy', 'Problem-Solving', 'Collaboration', 'Gratitude'
    ],
    Career: [
      'Career Planning', 'Skill Development', 'Work-Life Balance', 
      'Job Search Strategies', 'Networking', 'Career Change', 
      'Leadership Development', 'Confidence Building', 'Stress Management', 
      'Performance Improvement', 'Psychological Safety', 'Workplace Conflict'
    ],
    'Well being': [
      'Exercise', 'Nutrition', 'Meditation', 'Sleep', 
      'Financial Wellbeing', 'Spiritual Wellbeing', 'Bereavement'
    ]
  };

  // State variables
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  
  // Navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to set category from homepage navigation
  useEffect(() => {
    const state = location.state;
    if (state && state.category) {
      setSelectedCategory(state.category);
    }
  }, [location]);

  // Styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'black',
    color: 'white',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    boxSizing: 'border-box'
  };

  const topicContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    width: '100%',
    maxWidth: '1200px'
  };

  const topicButtonStyle = {
    backgroundColor: '#69B3C1',
    color: 'white',
    border: 'none',
    padding: '12px 24px',
    borderRadius: '9999px',
    cursor: 'pointer',
    fontSize: 'clamp(0.8rem, 3vw, 1rem)',
    fontWeight: '600',
    width: 'calc(33.333% - 1rem)',
    minWidth: '200px',
    transition: 'all 0.3s ease',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  };

  const titleStyle = {
    fontSize: 'clamp(1.5rem, 5vw, 2.5rem)',
    marginBottom: '2rem',
    color: 'white',
    textAlign: 'center'
  };

  const backButtonStyle = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    backgroundColor: '#69B3C1',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '9999px',
    cursor: 'pointer',
    fontSize: '16px'
  };

  const beliefSystemContainerStyle = {
    marginTop:'10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '60px'
  };

  // Topic selection handler
  const handleTopicSelect = (topic) => {
    navigate('/chat', { 
      state: { 
        category: selectedCategory, 
        subCategory: topic 
      } 
    });
  };

  // Reset selection
  const handleReset = () => {
    navigate('/homepage'); // Go back to previous page
  };

  return (
    <div style={containerStyle}>
      <button 
        style={backButtonStyle} 
        onClick={handleReset}
      >
        Back
      </button>

      <div style={beliefSystemContainerStyle}>
        <BeliefSystem/>
      </div>
      
      
      <motion.h1
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        style={titleStyle}
      >
        {selectedCategory} Topics
      </motion.h1>
      
      <div style={topicContainerStyle}>
        {selectedCategory && topicCategories[selectedCategory].map((topic) => (
          <motion.button
            key={topic}
            variants={{
              hover: {
                scale: 1.05,
                transition: { duration: 0.3 }
              }
            }}
            whileHover="hover"
            style={topicButtonStyle}
            onClick={() => handleTopicSelect(topic)}
          >
            {topic}
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default ArisDailyTopic;