import React from 'react';
import '../src/css/ToggleSwitch.css'; // Import the CSS file for styling
 
const ToggleSwitch = ({ checked, onChange }) => {
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="slider"></span>
    </label>
  );
};
 
export default ToggleSwitch;