import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [promptInstruction, setPromptInstruction] = useState(null);
  const [selectedBeliefSystem, setSelectedBeliefSystem] = useState({});

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser, 
      isAuthenticated, 
      setIsAuthenticated,
      promptInstruction,
      setPromptInstruction,
      selectedBeliefSystem,
      setSelectedBeliefSystem
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);