import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './Login.js';
import Homepage from './homepage.js';
import TextAssistant from './TextAssistant.js';
import SpeechAssistant from './SpeechAssistant.js';
import { UserProvider, useUser } from './UserContext.js';
import SpeechRecognitionAppDeep from './SpeechAssistantDeep.js';
import RelationshipAdvisor from './RelationshipType.js';
import RelationshipTopic from './RelationshipTopic.js';
import Insights from './insights.js';
import ArisDaily from './ArisDaily.js';
import ArisDailyTopic from './ArisDailyTopics.js';
import ArisDailyChat from './ArisDailyChat.js';



const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useUser();
  const location = useLocation();
  
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  
  return children;
};

const AppRoutes = () => {
  const { isAuthenticated, user, setUser, setIsAuthenticated } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = () => {
      if (isAuthenticated && location.pathname === '/') {
        navigate('/homepage', { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isAuthenticated, navigate, location]);

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    navigate('/', { replace: true });
  };

  return (
    <Routes>
      <Route 
        path="/" 
        element={
          isAuthenticated ? 
          <Navigate to="/homepage" replace /> : 
          <Login />
        } 
      />
      <Route 
        path="/homepage" 
        element={
          <ProtectedRoute>
            <Homepage username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
       <Route 
        path="/insights" 
        element={
          <ProtectedRoute>
            <Insights username={user}  />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/text-assistant" 
        element={
          <ProtectedRoute>
            <TextAssistant username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
        
      <Route 
        path="/speech-assistant" 
        element={
          <ProtectedRoute>
            <SpeechAssistant username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
        <Route 
        path="/speech-assistant-2" 
        element={
          <ProtectedRoute>
            <SpeechRecognitionAppDeep username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
       <Route 
        path="/relationship-advisor" 
        element={
          <ProtectedRoute>
            <RelationshipAdvisor username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />

<Route 
        path="/relationship-topic" 
        element={
          <ProtectedRoute>
            <RelationshipTopic username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/aris-daily" 
        element={
          <ProtectedRoute>
            <ArisDaily username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
        <Route 
        path="/chat" 
        element={
          <ProtectedRoute>
            <ArisDailyChat username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
       <Route 
        path="/daily-topics" 
        element={
          <ProtectedRoute>
            <ArisDailyTopic username={user} onLogout={logout} />
          </ProtectedRoute>
        } 
      />
    
    
    </Routes>
  );
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        <AppRoutes />
      </Router>
    </UserProvider>
  );
};

export default App;