import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useUser } from './UserContext.js';

const ArisDailyChat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  // Extract category and subCategory from router state
  const { category, subCategory } = location.state || {};

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [isBreakStatus, setIsBreakStatus] = useState(false);
  const [isConversationComplete, setIsConversationComplete] = useState(false);
   const { selectedBeliefSystem ,setSelectedBeliefSystem} = useUser();
  
  const messagesEndRef = useRef(null);

  // Scroll to bottom when messages change
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Styles (keeping the existing styles from the previous code)
  const theme = {
    primaryColor: '#69B3C1',
    backgroundColor: '#0A0A0A',
    textColor: '#FFFFFF',
    userMessageBg: '#1A1A1A',
    botMessageBg: '#69B3C1',
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      maxWidth: '100%',
      margin: '0 auto',
      backgroundColor: theme.backgroundColor,
      color: theme.textColor,
      fontFamily: "'Inter', sans-serif",
    },
    navbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 20px',
      backgroundColor: '#1A1A1A',
    },
    logo: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: 'white',
    },
    chatContainer: {
      flex: 1,
      overflowY: 'auto',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      scrollBehavior: 'smooth',
    },
    messageBase: {
      maxWidth: '60%',
      marginBottom: '10px',
      padding: '10px 15px',
      borderRadius: '12px 12px 12px 0',
      lineHeight: '1.4',
      wordWrap: 'break-word',
      alignSelf: 'flex-start',
      marginLeft:"20px"
    },
    userMessage: {
      alignSelf: 'flex-end',
      backgroundColor: theme.userMessageBg,
      border: `1px solid ${theme.primaryColor}`,
      borderRadius: '12px 12px 0 12px',
      marginRight:'20px'
    },
    botMessage: {
      alignSelf: 'flex-start',
      backgroundColor: theme.botMessageBg,
      color: theme.backgroundColor,
    },
    streamingMessage: {
      alignSelf: 'flex-start',
      backgroundColor: theme.primaryColor,
      color: theme.backgroundColor,
      padding:'20px',

    },

    botMessage1: {
      alignSelf: 'flex-start',
      backgroundColor: theme.botMessageBg,
      color: theme.backgroundColor,
      padding:'15px'
    },
    inputContainer: {
      width:'70%',
      margin:'10px auto',
      display: 'flex',
      padding: '15px',
      backgroundColor: '#1A1A1A',
      borderRadius:'10px',
      border: `1px solid ${theme.primaryColor}`,
    },
    inputContainer1: {
      width:'10%',
      margin:'10px auto',
      display: 'flex',
      padding: '15px',
      backgroundColor: '#1A1A1A',
      borderRadius:'10px',
      border: `1px solid ${theme.primaryColor}`,
    },
    input: {
      flex: 1,
      padding: '12px',
      backgroundColor: '#2A2A2A',
      color: theme.textColor,
      border: 'none',
      borderRadius: '8px',
      marginRight: '10px',
      fontSize: '16px',
    },
    sendButton: {
      padding: '12px 20px',
      backgroundColor: theme.primaryColor,
      color: theme.backgroundColor,
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
    backButton: {
      padding: '10px 20px',
      backgroundColor: theme.primaryColor,
      color: theme.backgroundColor,
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    loadingSpinner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.primaryColor,
      padding: '10px',
    },
  };

  // Automatic break status handling
  useEffect(() => {
    if (isConversationComplete && !isBreakStatus) {
      handleBreakStatusStreaming();
    }
  }, [isConversationComplete]);

  // Initial API call
  useEffect(() => {
    if (!category || !subCategory) {
      navigate('/homepage');
      return;
    }

    const fetchInitialMessage = async () => {
      try {
        const response = await axios.post(
          `https://lab-api.aris.ai/ARIS/CMW/${category.toLowerCase().replace(/\s+/g, '')}/${subCategory.toLowerCase()}`, 
          {
            user_name: user,
            break_status: isConversationComplete,
            history: [],
            belief:selectedBeliefSystem

          }
        );
        setConversationHistory(response.data.history || []);

        setMessages([{
          role: 'bot',
          content: response.data.response.question
        }]);
        setIsConversationComplete(response.data.response.is_next_conversation_is_advice);
        setIsInitialLoading(false);
      } catch (error) {
        setIsInitialLoading(false);
      }
    };

    fetchInitialMessage();
  }, []);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isConversationComplete) return;

    const newUserMessage = {
      role: 'user',
      content: inputMessage
    };
    const updatedMessages = [...messages, newUserMessage];
    setMessages(updatedMessages);
    setInputMessage('');
    setLoadingMessageIndex(updatedMessages.length);

    try {
      const response = await axios.post(
        `https://lab-api.aris.ai/ARIS/CMW/${category.toLowerCase().replace(/\s+/g, '')}/${subCategory.toLowerCase()}`, 
        {
          user_name: user,
          break_status: isConversationComplete,
          history: conversationHistory,
          user_response: inputMessage,
          belief:selectedBeliefSystem
        }
      );

      const botMessage = {
        role: 'bot',
        content: response.data.response.question
      };
      setConversationHistory(response.data.history);
      setMessages(prevMessages => [...prevMessages, botMessage]);
      setIsConversationComplete(response.data.response.is_next_conversation_is_advice);
      setLoadingMessageIndex(null);
    } catch (error) {
      console.error('Error sending message:', error);
      setLoadingMessageIndex(null);
    }
  };

  // Handle break status streaming
  const handleBreakStatusStreaming = async () => {
    try {
      setIsBreakStatus(true);
      
      // Add a new streaming message to messages
      setMessages(prevMessages => [...prevMessages, {
        role: 'bot',
        content: '',
        isStreaming: true
      }]);

      const response = await fetch(
        `https://lab-api.aris.ai/ARIS/CMW/${category.toLowerCase().replace(/\s+/g, '')}/${subCategory.toLowerCase()}`, 
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_name: user,
            break_status: true,
            history: conversationHistory,
            belief:selectedBeliefSystem ? selectedBeliefSystem : {}
          })
        }
      );
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      // Update the last message with streaming content
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value);
        
        // Update messages state to show streaming response
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const lastMessageIndex = updatedMessages.length - 1;
          updatedMessages[lastMessageIndex] = {
            ...updatedMessages[lastMessageIndex],
            content: (updatedMessages[lastMessageIndex].content || '') + chunk
          };
          return updatedMessages;
        });
      }

      // Reset conversation complete and break status
      // setIsConversationComplete(false);
      setIsBreakStatus(false);
    } catch (error) {
      console.error('Error in streaming response:', error);
      setIsBreakStatus(false);
    }
  };

  // Render method
  return (
    <div style={styles.container}>
      {/* Navbar */}
      <div style={styles.navbar}>
        <div style={styles.logo}>{category.toUpperCase()}</div>
        <button 
          style={styles.backButton}
          onClick={() => navigate('/homepage')}
        >
          Back
        </button>
      </div>

      {isInitialLoading ? (
        <div style={{
          ...styles.chatContainer, 
          justifyContent: 'center', 
          alignItems: 'center'
        }}>
          Loading...
        </div>
      ) : (
        <>
          <div style={styles.chatContainer}>
            {messages.map((message, index) => (
              <React.Fragment key={index}>
                <div 
                  style={{
                    ...styles.messageBase,
                    ...(message.role === 'user' ? styles.userMessage : 
                        message.isStreaming ? styles.streamingMessage : 
                        styles.botMessage1),
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {message.isStreaming ? (
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                  ) : (
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                  )}
                </div>
                {loadingMessageIndex === index + 1 && message.role === 'user' && (
                  <div style={styles.loadingSpinner}>Loading...</div>
                )}
              </React.Fragment>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {!isConversationComplete && (
            <div style={styles.inputContainer}>
              <input 
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                style={styles.input}
                placeholder="Type your message..."
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <button 
                style={styles.sendButton}
                onClick={handleSendMessage}
              >
                Send
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ArisDailyChat;