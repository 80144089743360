import React, { useEffect, useRef, useCallback, useState } from "react";
import { RealtimeClient } from "@openai/realtime-api-beta";
import { WavRecorder, WavStreamPlayer } from "./lib/wavtools/index.js";
import { getInstructions } from "./utils/conversation_config.js";
import { WavRenderer } from "./utils/wav_renderer.ts";
import { X, Mic, PhoneOff } from "react-feather";
import staticImage from './assests/aris-gif-pic.png';
import gifImage from './assests/SVKl.gif';
import { useUser } from './UserContext.js';
import arisLogo from './assests/aris-logo-cut.png';
import './css/speech.css';
import { position } from "@chakra-ui/react";
import voiceImg from './assests/voice.png';
import { useNavigate } from 'react-router-dom';
import PronunciationCustomizer from "./Pronunciation.js";
import ReactMarkdown from 'react-markdown';
import BeliefSystem from "./BeliefSystem.js";
import ToggleSwitch from "./ToggleButton.js";
import { 
  Heading, 
  Text, 
  UnorderedList, 
  ListItem, 
  Box,
  List
} from '@chakra-ui/react';




const ResponsePopup = ({ isOpen, onClose, streamingContent }) => {
  if (!isOpen) return null;
  const textColor = "black";
  const renderers = {
    h1: ({ children }) => (
        <Heading as="h1" size="xl" color={textColor} fontWeight="bold" mb={2}>
            {children}
        </Heading>
    ),
    h2: ({ children }) => (
        <Heading as="h2" size="lg" color={textColor} fontWeight="bold" mb={2}>
            {children}
        </Heading>
    ),
    h3: ({ children }) => (
        <Heading as="h3" size="md" color={textColor} fontWeight="bold" mb={2}>
            {children}
        </Heading>
    ),
    p: ({ children }) => (
        <Text fontSize="md" color={textColor} mb={2}>
            {children}
        </Text>
    ),
    strong: ({ children }) => (
        <Text as="strong" fontWeight="bold" color={textColor} mb={2}>
            {children}
        </Text>
    ),
    ul: ({ children }) => (
        <UnorderedList pl={5} mb={2} styleType="disc" color={textColor}>
            <List>
                {children}
            </List>
        </UnorderedList>
    ),
    li: ({ children }) => (
        <ListItem fontSize="md" color={textColor} mb={1}>
          <List>
            {children}
          </List>
        </ListItem>
    ),
    br: () => <Box height="2rem" /> // Ensures new line rendering
};
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        width: '80%',
        maxWidth: '700px',
        maxHeight: '80vh',
        position: 'relative',
        overflow: 'auto'
      }}>
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            padding: '5px'
          }}
        >
          ×
        </button>
        <div 
style={{ 
  marginTop: '20px', 
  color: 'black', 
  fontSize: '15px', // Adjust the text size
  lineHeight: '1.7' // Adjust the line spacing
}}
>
<ReactMarkdown >{streamingContent}</ReactMarkdown>
</div>
      </div>
    </div>
  );
};


const SpeechRecognitionApp = ({ onLogout }) => {


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [streamingContent, setStreamingContent] = useState('');
  const navigate = useNavigate();
  const isBeliefSystemSelected = () => {
    return selectedBeliefSystem !== null;
  };

  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
  if (apiKey !== "") {
    localStorage.setItem("tmp::voice_api_key", apiKey);
  }
  const { user } = useUser();
  const{promptInstruction}=useUser();

  const [turnEndType, setTurnEndType] = useState("none");
  const wavRecorderRef = useRef(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef(
    new RealtimeClient({
      apiKey: apiKey,
      dangerouslyAllowAPIKeyInBrowser: true

    })
  );
  

  const [items, setItems] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [instruct,setInstruct] =  useState(null);
  const [selectedVoice, setSelectedVoice] = useState("echo");
  const [error, setError] = useState(null);
  const [relationshipsofspeech, setRelationshipsforspeech] = useState({});
  const [selectedRelationship, setSelectedRelationship] = useState("");
  const [instructions, setInstructions] = useState(null);

  const[isapiCalled,setisapiCalled]=useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const[disconnected,setdisconnect]=useState(false);
  const[loading,setisloading]=useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
const [isSpecialMode, setIsSpecialMode] = useState(false);
  const { selectedBeliefSystem ,setSelectedBeliefSystem} = useUser();// Replace with your actual context
// If you're not using context, you'll need to pass selectedFinal as a prop from BeliefSystem component

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const showTemporaryAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000); // Hide after 3 seconds
  };

  const getButtonWidth = () => {
    if (windowWidth >= 1024) return '288px'; // lg
    if (windowWidth >= 768) return '256px';  // md
    return '100%';                           // mobile
  };

  const getFontSize = () => {
    return windowWidth >= 768 ? '16px' : '14px';
  };

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: getButtonWidth(),
    padding: '12px 16px',
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: isRecording ? '#ff4136' : '#6cb4c2',
    color: 'black',
    opacity: (!isConnected || !canPushToTalk) ? '0.5' : '1',
    fontSize: getFontSize(),
    transition: 'all 0.2s ease',
    userSelect: 'none',         // Add this
  WebkitUserSelect: 'none',   // Add this for Safari
  MozUserSelect: 'none',      // Add this for Firefox
  msUserSelect: 'none',       // Add this for IE/Edge
  WebkitTouchCallout: 'none'


  };


  const buttonStyle1 = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: getButtonWidth(),
    padding: '12px 16px',
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor:'#6cb4c2',
    color: 'black',
   
    fontSize: getFontSize(),
   
  
  marginTop:'20px'


  };

  const iconStyle = {
    width: '16px',
    height: '16px'
  };
  const textStyle = {
    pointerEvents: 'none', // Prevents text selection
    userSelect: 'none',         // Add this
    WebkitUserSelect: 'none',   // Add this for Safari
    MozUserSelect: 'none',      // Add this for Firefox
    msUserSelect: 'none'   
  };

  const cleanupResources = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    try {
      if (client.isConnected()) {
        client.disconnect();
      }
      await wavRecorder.end();
      await wavStreamPlayer.interrupt();
      setIsConnected(false);
      setdisconnect(false);
     
      setItems([]);
    } catch (error) {
      console.error("Error cleaning up resources:", error);
    }
  }, []);


//   const callApi = () => {
//     const currentItems = itemsRef.current;
//     fetch('https://skhcykhlbiyfk3jgydczwa34p40wayiz.lambda-url.us-east-1.on.aws/ARIS/history_summary', {
//         method: 'POST',
//         headers: {
//             'accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             name: user,
//             history: currentItems
//         })
//     })
//     .catch(error => console.error("Error calling API:", error));
// };

  const itemsRef = useRef(items);

// Update the ref whenever items change
useEffect(() => {
  // console.log("👻👻👻👻",items)
  itemsRef.current = items;
}, [items]);

useEffect(() => {
  window.history.replaceState({ page: 'current' }, '', window.location.href);
  // Event listeners for browser back button, reload, and tab close
  const handleBeforeUnload = (event) => {
    if (!disconnected) {  // Check if disconnect is false
    //  callApi();
  }
      event.preventDefault();
      event.returnValue = ''; // Required for most browsers
  };

  const handlePopState = (event) => {
    if (event.state && event.state.page === 'current' && !disconnected) {
     // callApi();
    }
  };

  // Set up event listeners
  window.addEventListener('beforeunload', handleBeforeUnload);  // Reload or tab close
  window.addEventListener('popstate', handlePopState);  // Browser back button

  // Cleanup listeners on component unmount
  return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
  };
}, [disconnected]);

const handleBackButtonClick = () => {
  if(disconnected){
    navigate('/homepage');
  }
  else{
   // callApi();
    navigate('/homepage');
  }
  
 
};
  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // ... (previous event listeners and setup code)

    // Add a new event listener for beforeunload
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      cleanupResources();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      cleanupResources();
    };
  }, [cleanupResources]);


  useEffect(() => {
    const fetchInstructions = async () => {
      if (isSpecialMode === true) {
        const client = clientRef.current;
        const newInstructions = await getInstructions(user, selectedBeliefSystem);
        setInstructions(newInstructions);
        console.log(newInstructions, "🦄🔥");
        
        // Update the client's session with the new instructions
        clientRef.current.updateSession({ instructions: newInstructions });
        client.updateSession({ voice: selectedVoice });
      }
    };
  
    fetchInstructions();
  
  }, [isSpecialMode]);
 
  const connectConversation = useCallback(async () => {
    setIsConnected(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    setisloading(true);
  
    try {
      // First, check if the tool exists and remove it if necessary
      try {
        const existingTools = client.getTools?.() || [];
        if (existingTools.some(tool => tool.name === 'get_user_prism')) {
          await client.removeTool('get_user_prism');
        }
      } catch (toolError) {
        console.warn('Error checking/removing existing tool:', toolError);
      }
  
      // Add the tool with error handling
      try {
        await client.addTool({
          type: "function",
          name: 'get_user_prism',
          description: 'Use this function whenever the user mentions a name or discusses a topic related to someone...',
          parameters: {
            type: 'object',
            properties: {
              name: {
                type: 'array',
                items: {
                  type: "string"
                },
                description: 'list of name of the persons in the query...',
              },
              query: {
                type: 'string',
                description: 'The specific topic or query about the person...',
              },
            },
            required: ['name', 'query'],
          },
        }, async ({ name, query }) => {
          try {
            const result = await fetch(
              'https://lab-api.aris.ai/ARIS/get_prism_topic',
              {
                method: 'POST',
                headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ names: name, query })
              }
            );
            
            if (!result.ok) {
              throw new Error(`API request failed with status ${result.status}`);
            }
            
            return await result.json();
          } catch (fetchError) {
            alert("Do not tap more than once 1");
            throw new Error('Failed to process user prism request');
          }
        });
        await client.addTool({
          type: "function",
          name: "get_past_history",
          description: "Retrieve information about past incidents or discussions related to a specific context provided by the user currently or right now. This function is particularly useful for recalling previously discussed topics or tracking user-related history. It is mandotory to check if the current one is disscued previoulsy before answering it ",
          parameters: {
            type: "object",
            properties: {
              user: {
                type: "string",
                description: "The name of the user. Provide the name as a single string.",
              },
              query: {
                type: "string",
                description: "A string describing the specific context or topic that the user is inquiring about or discussing.",
              }
            },
            required: ["user", "query"]
          }
        }, async ({ user, query }) => {
        try {
          const result = await fetch(
             `https://lab-api.aris.ai/ARIS/get_past_history?user=${encodeURIComponent(user)}&query=${encodeURIComponent(query)}`,
            {
              method: 'GET',
              headers: {
                'accept': 'application/json',
                
              },
            
            }
          );
              
              if (!result.ok) {
                throw new Error(`API request failed with status ${result.status}`);
              }
              
              return await result.json();
            } catch (fetchError) {
             
              throw new Error('Failed to process user prism request');
            }
          });
        


      } catch (addToolError) {
        alert('Do not tap more than once 2');
        throw new Error('Failed to initialize user prism tool');
      }
  
      setItems([]);
  
      // Initialize audio components with error handling
      try {
        await wavRecorder.begin();
        await wavStreamPlayer.connect();   
        await client.connect({ model: 'gpt-4o-realtime-preview-2024-12-17' });
      } catch (audioError) {
        console.error('Error initializing audio components:', audioError);
        if (audioError instanceof WebSocket) {
          console.error('WebSocket Error:', {
            code: audioError.code,
            message: audioError.message,
            reason: audioError.reason,
          });
        } else {
          console.error('General Error:', audioError.message);
        }
      
      //   throw new Error('Failed to initialize audio components');
      // }
        throw new Error('Failed to initialize audio components');
      }
  
      // Handle history query
      try {
        const result_query = await fetch(
          `https://lab-api.aris.ai/ARIS/history_query?name=${encodeURIComponent(user)}`,
          {
            method: 'POST',
            headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: user })
          }
        );
  
        if (!result_query.ok) {
          throw new Error(`History query failed with status ${result_query.status}`);
        }
  
        const json_query = await result_query.json();
        
        await client.sendUserMessageContent([
          {
            type: "input_text",
            text: json_query
          }
        ]);
  
        if (client.getTurnDetectionType() === "server_vad") {
          await wavRecorder.record(data => client.appendInputAudio(data.mono));
        }
  
        setisloading(false);
  
      } catch (historyError) {
        alert('Do not tap more than once 3');
        throw new Error('Failed to load conversation history');
      }
  
    } catch (error) {
      // alert("Do not tap more than once 4");
      console.log(error,"🎺🎺🎺")
      setError(error.message || "Failed to connect conversation. Please try again.");
      setIsConnected(false);
      setisloading(false);
    }
  }, [user]);
  
  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      const client = clientRef.current;
      if (client) {
        try {
          client.removeTool('get_user_prism');
        } catch (error) {
          console.warn('Error cleaning up tool:', error);
        }
      }
    };
  }, []);

  const disconnectConversation = useCallback(async () => {
    setisloading(false);
  //   const currentItems = itemsRef.current;
  //   var result = fetch(
  //     'https://skhcykhlbiyfk3jgydczwa34p40wayiz.lambda-url.us-east-1.on.aws/ARIS/history_summary',
  //     {
  //         method: 'POST',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //             // eslint-disable-next-line no-restricted-globals
  //             name: user,
  //             history: currentItems
  //         })
  //     }
  // );
  //callApi();
  setdisconnect(true);
    setIsConnected(false);
    setItems([]);
     
 
   
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
  

    try {
      
    setisapiCalled(false);
      client.disconnect();
      client.removeTool("get_user_prism");
      client.removeTool("get_past_history");
      await wavRecorder.end();
      await wavStreamPlayer.interrupt();
    } catch (error) {
      console.error("Error disconnecting conversation:", error);
      setError("Failed to disconnect conversation. Please try again.");
    }
  }, []);


  const deleteConversationItem = useCallback(async id => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);
  const recordingStateRef = useRef(false);
  const startRecording = async () => {
    try {
      if (recordingStateRef.current) {
        showTemporaryAlert('Recording is already in progress. Please wait.');
        return;
      }
    
    setIsRecording(true);
    recordingStateRef.current = true;
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record(data => client.appendInputAudio(data.mono));
  }catch (error) {
    console.error('Recording error:', error);
    showTemporaryAlert('Unable to start recording. Please try again.');
    setIsRecording(false);
    recordingStateRef.current = false;
  }
  };

  const stopRecording = async () => {
    try {
      if (!recordingStateRef.current) {
        showTemporaryAlert('No active recording to stop.');
        return;
      }

    setIsRecording(false);
    recordingStateRef.current = false;
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  } catch (error) {
    console.error('Stop recording error:', error);
    showTemporaryAlert('Error stopping recording. Please try again.');
  }
  };

  const handleToggleChange = (event) => {
    changeTurnEndType(event.target.value);
    setTurnEndType(event.target.value);
  };


  const changeTurnEndType = async value => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === "none" && wavRecorder.getStatus() === "recording") {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === "none" ? null : { type: "server_vad" }
    });
    if (value === "server_vad" && client.isConnected()) {
      await wavRecorder.record(data => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === "none");
  };


  // const changeTurnEndType = async value => {
  //   const client = clientRef.current;
  //   const wavRecorder = wavRecorderRef.current;
  //   if (value === "none" && wavRecorder.getStatus() === "recording") {
  //     await wavRecorder.pause();
  //   }
  //   client.updateSession({
  //     turn_detection: value === "none" ? null : { type: "server_vad" }
  //   });
  //   if (value === "server_vad" && client.isConnected()) {
  //     await wavRecorder.record(data => client.appendInputAudio(data.mono));
  //   }
  //   setCanPushToTalk(value === "none");
  // };

  const handleVoiceChange = async (event) => {
    const newVoice = event.target.value;
    
    try {
      if (isConnected) {
        // Disconnect the current conversation
        await disconnectConversation();
      }

      // Update the voice
      setSelectedVoice(newVoice);
      await clientRef.current.updateSession({ voice: newVoice });

      if (isConnected) {
        // Reconnect the conversation with the new voice
        await connectConversation();
      }
    } catch (error) {
      console.error("Error changing voice:", error);
      setError("Failed to change voice. Please try again.");
    }
  };


  const handleToggle = () => {
    if (isBeliefSystemSelected()) {
      const newSpecialMode = !isSpecialMode;
      setIsSpecialMode(newSpecialMode);
      
      // Reset selectedBeliefSystem when toggle is turned off
      if (!newSpecialMode) {
        setSelectedBeliefSystem(null);
      }
    }
  };


  const handleProceed = async () => {
    try {
      setIsPopupOpen(true);
      setStreamingContent('');

      const response = await fetch(`https://lab-api.aris.ai/ARIS/action-items?name=${user}`, {
        method: 'POST',
       
      
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const text = decoder.decode(value);
        setStreamingContent(prevContent => prevContent + text);
      }

    } catch (error) {
      console.error('Error:', error);
      setStreamingContent('Error occurred while fetching data');
    }
  };

  const fetchRelationshipsforspeech = async (userName) => {
    try {
      const response = await fetch(`https://lab-api.aris.ai/ARIS/relationship?name=${encodeURIComponent(userName)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setRelationshipsforspeech(data.relationship);
     
    } catch (error) {
      console.error("Error fetching relationships:", error);
      setRelationshipsforspeech({});
      return [];
    }
  };

  useEffect(() => {
    fetchRelationshipsforspeech(user);
  }, [user]);
  const handleRelationshipChange = async (event) => {
    // console.log("🦄🦄",event.target.value);
    const newRelation = event.target.value;
    setSelectedRelationship(newRelation);
    
    try {
      const newInstructions = await getInstructions(user );
      setInstructions(newInstructions);
      // console.log(typeof(newInstructions.stringify()),"🤡");
      
      // Update the client's session with the new instructions
      clientRef.current.updateSession({ instructions: newInstructions.stringify() });
    } catch (error) {
      console.error("Error updating instructions:", error);
      setError("Failed to update instructions. Please try again.");
    }
  };





  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // console.log(promptInstruction,"✅🤡");
    // console.log(instructions,"👽🫥");

if(instructions){
  //client.updateSession({ instructions: instructions });

}else{
  // console.log(promptInstruction,"🐺🦊");
  client.updateSession({ instructions: promptInstruction });
}

 
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });
    
    client.updateSession({voice:selectedVoice});

    client.on("realtime.event", realtimeEvent => {
     console.log("Realtime event:👽", realtimeEvent);
    });
    client.on("error", event => console.error(event));
    client.on("conversation.updated", async ({ item, delta }) => {
      var items = client.conversation.getItems();
      
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
    
        // Only call history summary when the item is fully completed
        const currentItems = itemsRef.current;
        const lastElement=currentItems[currentItems.length-1];
        const isUserWithEmptyMessage =lastElement.role === "user" && lastElement.message.trim() === "";
        // console.log("👻👻👻👻",currentItems);
        if(!isUserWithEmptyMessage){
      
        fetch('https://lab-api.aris.ai/ARIS/history_summary', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: user,
                history: currentItems
            })
        })
      

        .catch(error => console.error("Error calling API:", error));
      }
    }
      
      items = items
        .filter(item => item.type === 'message')
        .map(item => {
            if (item.role === 'user' || item.role === 'assistant') {
              // console.log(item.role, item.formatted.transcript, "✅✅");
                return {
                    role: item.role,
                    message: item.formatted.transcript
                };
            }
            return null;
        })
        .filter(Boolean);
    
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, [selectedVoice]);

  return   (
    <div className="page-container" style={{ position: 'relative', minHeight: '100vh' }}>
    <div className="content-container" style={{ paddingTop: '20px' }}>
      {/* Back button */}
      <div style={{ position: 'absolute', top: '23px', left: '25px'}}>
       
        <button
          size={30} 
          color="#6cb4c2" 
          onClick={handleBackButtonClick} 
          style={{ cursor: 'pointer' ,width:'50px',height:'27px',backgroundColor:'black',color:'#69b3c1',border:'1.2px solid #69b3c1',borderRadius:'5px'}}
        >Back</button>
      </div>


      {!isConnected &&(
      <BeliefSystem/>
      )
}
      <PronunciationCustomizer/>


    
{!isConnected &&(
     
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '20px', right: '20px' }}>
        <img src={voiceImg} width={40} height={40} alt="Voice" />
        <select
          value={selectedVoice}
          onChange={handleVoiceChange}
          style={{
            padding: '5px',
            borderRadius: '5px',
            backgroundColor: 'black',
            color: '#6cb4c2',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            marginLeft: '10px',
          }}
        >
          <option value="alloy">Alloy</option>
          <option value="echo">Echo</option>
        
          <option value="shimmer">Shimmer</option>
        </select>
      </div>
)}

      {/* Logo and title */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <img 
          src={arisLogo} 
          width="130px" 
          height="57px" 
          alt="Aris Logo" 
          style={{ marginRight: '10px', cursor: 'pointer' }} 
          onClick={() => navigate('/homepage')} 
        />
        {/* <h1 style={{ margin: 0, cursor: 'pointer' }} onClick={() => navigate('/homepage')}>
          Aris Voice
        </h1> */}
      </div>
      <h4 style={{ color: 'white', textAlign: 'center', marginTop: '15px',marginBottom:'20px' }}>
        Live Coaching Session
      </h4>
      {/* Status image */}
      <img
        src={isConnected ? gifImage : staticImage}
        alt="App status"
        className="status-image"
        onClick={isConnected ? disconnectConversation : connectConversation}
        style={{ display: 'block', margin: '0 auto', cursor: 'pointer' }}
      />
      <h4 style={{ color: 'white', textAlign: 'center', marginTop: '30px',marginBottom:'20px' }}>
        {isConnected ? "Tap to Disconnect" : "Tap to Connect"}
      </h4>
      <h4 style={{ color: 'white', textAlign: 'center', marginTop: '30px',marginBottom:'20px' }}>
      {loading && (
         <h3 style={{ color: 'white', textAlign: 'center',marginBottom:'10px' }}>
         Your session is being created...
       </h3>
      )}
      </h4>
   
{!isConnected&&(
    <select 
          value={turnEndType} 
          onChange={handleToggleChange}
          style={{ 
            backgroundColor: 'black', 
            color: '#6cb4c2', 
            padding: '10px', 
            border: '1px solid #6cb4c2',
            borderRadius: '5px',
            marginBottom: '20px',
            marginTop:'20px',
            width: '20%'
          }}
        >
          <option value="none">Manual</option>
          <option value="server_vad">VAD</option>
        </select>

)}
        {isConnected && canPushToTalk && !loading && (
          <button
            onMouseDown={startRecording}
            onMouseUp={stopRecording}
            onTouchStart={(e) => {
              e.preventDefault(); // Prevents default touch behavior
              startRecording(e);
            }}
            onTouchEnd={(e) => {
              e.preventDefault(); // Prevents default touch behavior
              stopRecording(e);
            }}

            disabled={!isConnected || !canPushToTalk}
            style={buttonStyle}
          >
                <Mic style={iconStyle} />
      <span style={textStyle}>
        {isRecording ? "Release to Send" : "Press and hold to Talk"}
      </span>
          </button>

          )}
         

         {isConnected && canPushToTalk && !loading && (
 
    <button onClick={handleProceed} style={buttonStyle1}>Get action items</button>
    
    
)}


{!isConnected &&
<div style={{ 
      display: 'flex', 
      alignItems: 'center',
      marginTop:'10px',
      opacity: isBeliefSystemSelected() ? 1 : 0.8,
      cursor: isBeliefSystemSelected() ? 'pointer' : 'not-allowed'
    }}>
      <span style={{ color: '#6cb4c2', marginRight: '10px' }}>
        {isBeliefSystemSelected() 
          ? `Enable Special Mode (${selectedBeliefSystem.religion} - ${selectedBeliefSystem['sub-religion']})` 
          : 'Please select a belief system first'}
      </span>
      <div
        onClick={handleToggle}
        style={{
          width: '50px',
          height: '24px',
          backgroundColor: isBeliefSystemSelected() ? (isSpecialMode ? '#6cb4c2' : '#333') : '#333',
          borderRadius: '12px',
          position: 'relative',
          transition: 'background-color 0.3s',
          cursor: isBeliefSystemSelected() ? 'pointer' : 'not-allowed',
          border: '1px solid #6cb4c2'
        }}
      >
        {/* Toggle switch circle */}
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'white',
            borderRadius: '50%',
            position: 'absolute',
            top: '1px',
            left: isSpecialMode ? '28px' : '2px',
            transition: 'left 0.3s',
          }}
        />
      </div>
      <div style={{
        marginLeft: '8px',
        padding: '2px 6px',
        backgroundColor: '#E2E2E2',
        borderRadius: '4px',
        fontSize: '12px',
        color: '#666666',
        fontWeight: '500',
      }}>
        BETA
      </div>
    </div>

        }


           </div>
        
    <ul className="circles" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', margin: 0, padding: 0 }}>
      {[...Array(10)].map((_, index) => (
        <li key={index}></li>
      ))}
    </ul>

    <ResponsePopup 
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        streamingContent={streamingContent}
      />
  </div>
  );
};

export default SpeechRecognitionApp;