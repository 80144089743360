import React, { useState,useEffect, useRef  } from 'react';
import { useUser } from './UserContext.js';
const BeliefSystem = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPrimary, setSelectedPrimary] = useState(null);
  const [selectedSecondary, setSelectedSecondary] = useState(null);
  const [selectedTertiary, setSelectedTertiary] = useState(null);
  const { selectedBeliefSystem, setSelectedBeliefSystem } = useUser();
  const [selectedFinal, setSelectedFinal] = useState(null);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (!selectedBeliefSystem) {
      setSelectedPrimary(null);
      setSelectedSecondary(null);
      setSelectedTertiary(null);
      setSelectedFinal(null);
      setIsOpen(false);
    }
  }, [selectedBeliefSystem]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const baseButtonStyle = {
    backgroundColor: 'black',
    color: '#6cb4c2',
    border: '1.2px solid #6cb4c2',
    borderRadius: '5px',
    padding: '8px 15px',
    cursor: 'pointer',
    marginRight: '10px'
  };

  const styles = {
    dropdownContainer: {
      position: 'relative',
      bottom:'32px',
      width: '300px'
    },
    mainButton: {
      ...baseButtonStyle,
      width: '100%',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: 0
    },
    dropdownMenu: {
      position: 'absolute',
      top: '70%',
      left: 0,
      width: '100%',
      backgroundColor: 'black',
      border: '1.2px solid #6cb4c2',
      borderRadius: '5px',
      marginTop: '5px',
      zIndex: 1000,
      maxHeight: '400px',
      overflowY: 'auto'
    },
    primaryButton: {
      ...baseButtonStyle,
      width: '100%',
      textAlign: 'left',
      marginRight: 0,
      borderWidth: 0,
      borderRadius: 0,
      borderBottom: '1px solid #6cb4c2',
      display: 'flex',
      justifyContent: 'space-between'
    },
    secondaryButton: {
      ...baseButtonStyle,
      width: '100%',
      textAlign: 'left',
      marginRight: 0,
      borderWidth: 0,
      borderRadius: 0,
      paddingLeft: '25px',
      backgroundColor: '#0a0a0a'
    },
    tertiaryButton: {
      ...baseButtonStyle,
      width: '100%',
      textAlign: 'left',
      marginRight: 0,
      borderWidth: 0,
      borderRadius: 0,
      paddingLeft: '45px',
      backgroundColor: '#141414'
    }
  };



  // Primary categories with their full subcategory data
  const beliefSystemData = {
    'Christianity': {
      'Roman Catholicism': null,
      'Protestantism': {
        'Baptist': null,
        'Lutheran': null,
        'Methodist': null,
        'Pentecostal': {
          'Assemblies of God': null,
          'Church of God': null
        },
        'Presbyterian': {
          'Presbyterian Church USA (PCUSA)': null,
          'Presbyterian Church in America (PCA)': null
        },
        'Non-denominational': {
          'Evangelical Non-denominational': null,
          'Charismatic Non-denominational': null
        }
      },
      'Eastern Orthodoxy': {
        'Greek Orthodox': null,
        'Russian Orthodox': null,
        'Coptic Orthodox': null
      },
      'Anglicanism / Episcopal Church': null,
      'Jehovah\'s Witnesses': null,
      'Seventh-day Adventists': null,
      'Church of Jesus Christ of Latter-day Saints (Mormons)': null,
      'Other Christian Traditions': null
    },
    'Judaism': {
      'Orthodox': null,
      'Conservative': null,
      'Reform': null,
      'Reconstructionist': null,
      'Cultural Judaism (Secular Jewish)': null,
      'Other Jewish Traditions': null
    },
    'Islam': {
      'Sunni': null,
      'Shia': null,
      'Sufi': null,
      'Ahmadiyya': null,
      'Non-denominational / Other': null
    },
    'Hinduism': {
      'Vaishnavism': null,
      'Shaivism': null,
      'Shaktism': null,
      'Smartism': null,
      'ISKCON': null,
      'Other Hindu Traditions': null
    },
    'Buddhism': {
      'Theravāda': null,
      'Mahāyāna': null,
      'Vajrayāna': null,
      'Zen': null,
      'Tibetan Buddhism': null,
      'Nichiren Buddhism': null,
      'Other Buddhist Traditions': null
    },
    'Sikhism': {
      'Traditional Sikhism': null,
      'Modern Interpretations': null
    },
    'Spirituality / New Age': {
      'Meditation-focused': null,
      'Mindfulness-based': null,
      'Cosmic Energy / Chakras': null,
      'Law of Attraction': null,
      'Energy Healing': null,
      'Nature-based Spirituality': null,
      'Other Spiritual Practices': null
    },
    'Atheism / Agnosticism / Secular Humanism': {
      'Atheism': null,
      'Agnosticism': null,
      'Secular Humanism': null,
      'Ethical Culture': null
    },
    'Indigenous / Native Traditions': {
      'Native American Spirituality': null,
      'Aboriginal Spirituality': null,
      'Shamanism': null,
      'Other Indigenous Traditions': null
    },
    'Other Faiths': {
      'Bahá\'í Faith': null,
      'Jainism': null,
      'Zoroastrianism': null,
      'Confucianism': null,
      'Taoism': null,
      'Shinto': null,
      'Rastafarianism': null,
      'Scientology': null
    }
  };

  const createBeliefSystemPath = (primary, secondary = null, tertiary = null, final = null) => {
    const path = {
      category: primary,
      category1: secondary,
      category2: tertiary,
      category3: final
    };
    
    // Remove null values while maintaining order
    return Object.entries(path).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const handlePrimaryClick = (category) => {
    const newPrimary = category === selectedPrimary ? null : category;
    setSelectedPrimary(newPrimary);
    setSelectedSecondary(null);
    setSelectedTertiary(null);
    setSelectedFinal(null);
    
    if (newPrimary) {
      const beliefSystemDict = createBeliefSystemPath(newPrimary);
      setSelectedBeliefSystem(beliefSystemDict);
    } else {
      setSelectedBeliefSystem(null);
    }
  };

  const handleSecondaryClick = (category, hasSubcategories) => {
    const newSecondary = category === selectedSecondary ? null : category;
    setSelectedSecondary(newSecondary);
    setSelectedTertiary(null);
    setSelectedFinal(null);

    if (!hasSubcategories && newSecondary) {
      const beliefSystemDict = createBeliefSystemPath(selectedPrimary, newSecondary);
      setSelectedBeliefSystem(beliefSystemDict);
      setIsOpen(false);
    }
  };

  const handleTertiaryClick = (category, hasSubcategories) => {
    const newTertiary = category === selectedTertiary ? null : category;
    setSelectedTertiary(newTertiary);
    setSelectedFinal(null);

    if (!hasSubcategories && newTertiary) {
      const beliefSystemDict = createBeliefSystemPath(selectedPrimary, selectedSecondary, newTertiary);
      setSelectedBeliefSystem(beliefSystemDict);
      setIsOpen(false);
    }
  };

  const handleFinalClick = (category) => {
    setSelectedFinal(category);
    const beliefSystemDict = createBeliefSystemPath(
      selectedPrimary,
      selectedSecondary,
      selectedTertiary,
      category
    );
    setSelectedBeliefSystem(beliefSystemDict);
    setIsOpen(false);
  };

  const renderSubcategories = (categories, subcategory = 1) => {
    return Object.entries(categories).map(([category, subcategories]) => {
      const hasSubcategories = subcategories && Object.keys(subcategories).length > 0;
      const isSelected = subcategory === 1 ? selectedSecondary === category : selectedTertiary === category;
      const buttonStyle = subcategory === 1 ? styles.secondaryButton : styles.tertiaryButton;
      
      return (
        <div key={category}>
          <button
            style={buttonStyle}
            onClick={() => {
                if (subcategory === 1) {
                    handleSecondaryClick(category, hasSubcategories);
                  } else if (subcategory === 2) {
                    handleTertiaryClick(category, hasSubcategories);
                  } else {
                    handleFinalClick(category);
                  }
            }}
          >
            {category}
            {hasSubcategories && <span style={{float: 'right'}}>{isSelected ? '▼' : '▶'}</span>}
          </button>
          {isSelected && hasSubcategories && (
            <div>
              {renderSubcategories(subcategories, subcategory + 1)}
            </div>
          )}
        </div>
      );
    });
  };

 return (
    <div style={styles.dropdownContainer} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={styles.mainButton}
      >
        <span>
          {selectedFinal || selectedTertiary || selectedSecondary || selectedPrimary || 'Select Belief System'}
        </span>
        <span>{isOpen ? '▲' : '▼'}</span>
      </button>

      {isOpen && (
        <div style={styles.dropdownMenu}>
           <button
      style={{
        ...styles.primaryButton,
        borderBottom: '2px solid #6cb4c2'  // make it stand out a bit more
      }}
      onClick={() => {
        setSelectedPrimary(null);
        setSelectedSecondary(null);
        setSelectedTertiary(null);
        setSelectedFinal(null);
        setSelectedBeliefSystem(null);
        setIsOpen(false);
      }}
    >
      <span>Select None</span>
    </button>
    
          {Object.keys(beliefSystemData).map((category) => (
            <div key={category}>
              <button
                style={styles.primaryButton}
                onClick={() => handlePrimaryClick(category)}
              >
                <span>{category}</span>
                <span>{selectedPrimary === category ? '▼' : '▶'}</span>
              </button>
              {selectedPrimary === category && renderSubcategories(beliefSystemData[category])}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BeliefSystem;