import React from 'react';
import { useNavigate } from 'react-router-dom';

const ArisDaily = () => {
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'black',
    margin: 'auto auto',
    padding: 0
  };

  const titleStyle = {
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '30px'
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px'
  };

  const buttonStyle = {
    backgroundColor: '#69B3C1',
    color: 'white',
    border: 'none',
    padding: '12px 24px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '200px',
    transition: 'transform 0.3s ease'
  };

  const handleButtonClick = (category) => {
    // Navigate to ArisDailyTopic page with the selected category
    navigate('/daily-topics', { state: { category } });
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Aris Daily</h1>
      <div style={buttonContainerStyle}>
        <button 
          style={buttonStyle}
          onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          onClick={() => handleButtonClick('Mindset')}
        >
          Mindset
        </button>
        <button 
          style={buttonStyle}
          onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          onClick={() => handleButtonClick('Wellbeing')}
        >
          Wellbeing
        </button>
        <button 
          style={buttonStyle}
          onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
          onClick={() => handleButtonClick('Career')}
        >
          Career
        </button>
      </div>
    </div>
  );
};

export default ArisDaily;