import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import BeliefSystem from './BeliefSystem.js';

import dating from '../src/assests/Icons/dating.png';

import businessPartner from '../src/assests/Icons/businessPartner.png';

import family from '../src/assests/Icons/family.png';

import friends from '../src/assests/Icons/friends.png';

import marriagePartner from '../src/assests/Icons/marriagePartner.png';

import rommate from '../src/assests/Icons/rommate.png';

import sportsTeammate from '../src/assests/Icons/sportsTeammate.png';

import workColleague from '../src/assests/Icons/workColleague.png';
import { X } from 'lucide-react';


import { useUser } from './UserContext.js';
import { border } from '@chakra-ui/react';




const RelationshipType = () => {
    const [selectedRelation, setSelectedRelation] = useState('');
    const [relationshipMapping, setRelationshipMapping] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [relationshipData, setRelationshipData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedRelationship, setSelectedRelationship] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [relationshipInfo, setrelationshipInfo] = useState('');
    const [error, setError] = useState(null);
    const [isdropdown, setDropdowndisplay] = useState(false);
    

    const navigate = useNavigate();
     const { user } = useUser();
    const location = useLocation();
    // const { relationshipInfo, error } = location.state || {};
    const relationships = [
        { name: 'Dating', iconPath: dating },
        { name: 'Marriage', iconPath: marriagePartner },
     
        { name: 'Family', iconPath: family },
        { name: 'Friends', iconPath: friends },
        { name: 'Workplace', iconPath: workColleague },
       
        { name: 'Sports', iconPath: sportsTeammate },
        { name: 'Roommates', iconPath: rommate },
        { name: 'Others', iconPath:businessPartner },
        
    ];

    const containerStyle = {
      padding: '20px',
      backgroundColor: '#000000',
      minHeight: '100vh',
      color: 'white'
    };
    const titleStyle = {
      textAlign: 'center',
      fontSize: '1.7rem',
      marginBottom: '40px',
      fontFamily: 'Arial, sans-serif'
    };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '40px',
        padding: '0 20px'
    };

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#69b3c1',
        color: 'white',
        padding: '15px 25px',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '1.1rem',
        transition: 'transform 0.2s',
        width: '100%'
    };

    const iconStyle = {
        marginRight: '15px',
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '4px'
    };

    const iconImageStyle = {
        width: '25px',
        height: '25px',
        filter: 'brightness(0) invert(1)' // This makes the icons white
    };

    const updateButtonStyle = {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#69b3c1',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block', // Makes the button act like a block element
        margin: '40px auto', // Centers the button horizontally
      };
    
      const modalOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
       
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      };
    
      const modalContentStyle = {
        position: 'relative',
        backgroundColor: 'black',
        padding: '60px',
        borderRadius: '10px',
        maxWidth: '80vw',
        maxHeight: '80vh',
        overflow: 'auto',
        color: 'white',
        borderTop: '2px solid #69b3c1', // Border on top
        borderBottom: '2px solid #69b3c1', // Border on bottom
        borderLeft: '2px solid #69b3c1', // Border on left
      };
      const closeButtonStyle = {
        position: 'absolute',
        top: '15px',
        right: '15px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        padding: '5px'
      };
    
      const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        marginBottom:'20px'
    

      };
    
      const cellStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left'
      };

      const backButtonContainerStyle = {
        position: 'absolute',
        top: '23px',
        left: '40px'
      };
      const backButtonStyle = {
        cursor: 'pointer',
        width: '50px',
        height: '27px',
        backgroundColor: 'black',
        color: '#69b3c1',
        border: '1.2px solid #69b3c1',
        borderRadius: '5px'
      };
    
      
      const beliefSystemContainerStyle = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px'
      };

      const inputStyle = {
        width: '90%',
        padding: '6px',
        border: '1px solid #69b3c1',
        borderRadius: '4px',
        fontSize: '14px'
      };
    
      const doneButtonStyle = {
        marginTop: '20px',
        padding: '10px 30px',
        backgroundColor: '#69b3c1',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block',
        margin: '20px auto'
      };
    // const updateRelationship = async (userName) => {
    //     try {
    //       const response = await fetch(`https://lab-api.aris.ai/ARIS/relationship-mapping?user_name=${userName}`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //            'Authorization': `Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM`

    //         }
    //       });
      
    //       if (!response.ok) {
    //         throw new Error(`HTTP error! status: ${response.status}`);
    //       }
    //       const data = await response.json();
    //       setRelationshipData(data);
    //     } catch (err) {
    //       console.error('Error fetching relationship data:', err);
    //       throw err;
    //     }
    //   };

   
    const handleRelationClick = async (relationName) => {
        try {
            setSelectedRelation(relationName);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM`
                    // Add any additional headers if needed
                    // 'Authorization': 'Bearer your-token'
                },
                body: JSON.stringify({
                    user_name: 'Niko',
                    topic: relationName
                })
            };
            
            
            // Make API call
            const response = await fetch(`https://lab-api.aris.ai/ARIS/relationship-mapping?user_name=${user}&topic=${relationName}`,requestOptions);
            const data = await response.json();
            const modifiedData = Object.keys(data).reduce((acc, key) => {
              // Check if the key exists in setRelationshipInfo
              acc[key] = selectedRelationship|| null;
              return acc;
            }, {});
        
            const validRelationships = Object.entries(data)
            .filter(([_, value]) => value !== null)
            .reduce((acc, [key, value]) => ({
                ...acc,
                [key]: value
            }), {});
            console.log(validRelationships,"...🍔....",modifiedData);
            
            // Navigate with both relationName and API response data
            navigate('/relationship-topic', { 
                state: { 
                    relationship: relationName,
                    relationshipData: modifiedData ,
                    rawData: data 
                } 
            });
        } catch (error) {
            console.error('Error fetching relationship data:', error);
            // You might want to handle the error appropriately
            // Either show an error message or navigate with just the relationName
            navigate('/relationship-topic', { 
                state: { 
                    relationship: relationName,
                    error: 'Failed to fetch relationship data'
                } 
            });
        }
    };
    const handleBackButtonClick = () => {
        
       
      navigate('/homepage');
    
    
   
  };


  const handleUpdateClick = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://lab-api.aris.ai/ARIS/relationship-mapping?user_name=${user}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
             'Authorization': `Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM`

          }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setRelationshipData(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error updating relationship:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleValueChange = (category, relation, newValue) => {
    setRelationshipData(prevData => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [relation]: newValue
      }
    }));
  };

  const handleRelationshipChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedRelationship(selectedValue);
    // You can perform additional actions here when a relationship is selected
    console.log('Selected Relationship:', selectedValue);
  };

  const handleDoneClick = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://lab-api.aris.ai/ARIS/relationship-mapping?user_name=${user}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
             'Authorization': `Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM`

          },
        body: JSON.stringify(relationshipData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleRelationshipApi=async()=>{
    setDropdowndisplay(true);
  
      setIsLoading(true);
      try {
        const response = await fetch(`https://lab-api.aris.ai/ARIS/relationship?name=${encodeURIComponent(user)}&type=List`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch relationship data');
        }
        
        const data = await response.json();
        // Store the API response in the new state
        
        // Optional: You might want to update relationshipInfo or do something with the data
        setrelationshipInfo(data);
      } catch (err) {
        console.error('Error fetching relationship data:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
  


  // const renderRelationshipTable = (data) => {
  //   return Object.entries(data).map(([category, relations]) => (
  //     <div key={category} className="mb-6">
  //       <h3 className="text-lg font-semibold mb-2">{category}</h3>
  //       <table style={tableStyle}>
  //         <tbody>
  //           {Object.entries(relations).map(([relation, value]) => (
  //             <tr key={relation}>
  //               <td style={cellStyle}>{relation}</td>
  //               <td style={cellStyle}>
  //                 <input
  //                   type="text"
  //                   value={relationshipData[category][relation] || ''}
  //                   onChange={(e) => handleValueChange(category, relation, e.target.value)}
  //                   placeholder="Not specified"
  //                   style={inputStyle}
  //                 />
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   ));
  // };

  console.log(relationshipData,"........");


  return (
    <div style={containerStyle}>
      <div style={{ position: 'absolute', top: '23px', left: '40px'}}>
        <button
          size={30}
          color="#6cb4c2"
          onClick={handleBackButtonClick}
          style={{ cursor: 'pointer', width:'50px', height:'27px', backgroundColor:'black', color:'#69b3c1', border:'1.2px solid #69b3c1', borderRadius:'5px'}}
        >
          Back
        </button>
      </div>
     
      <h1 style={titleStyle}>Relationship Advice</h1>

      <div style={beliefSystemContainerStyle}>
        <BeliefSystem/>
      </div>
      <button
        size={30}
        color="#6cb4c2"
        onClick={handleRelationshipApi}
        style={updateButtonStyle}
      >
        Choose a name
      </button>
{isdropdown &&(
            <div style={{
              marginTop: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '35px'
      }}>
        <select
          value={selectedRelationship}
          onChange={handleRelationshipChange}
          style={{
            width: '300px',
            padding: '10px',
            borderRadius: '5px',
            border: '1.2px solid #69b3c1',
            backgroundColor: 'black',
            color: '#69b3c1',
            appearance: 'none',  // Removes default browser styling
            cursor: 'pointer'
          }}
        >
          <option value="" disabled>Select a name</option>
          {relationshipInfo && relationshipInfo.map((info, index) => (
            <option 
              key={index} 
              value={info.name || info}
              style={{
                backgroundColor: 'black',
                color: '#69b3c1'
              }}
            >
              {info.name || info}
            </option>
          ))}
        </select>
       

      
      </div>
      

        )}

        

      {selectedRelationship &&

            <div style={gridContainerStyle}>
              
                {relationships.map((relation) => (
                    <button
                        key={relation.name}
                        style={buttonStyle}
                        onClick={() => handleRelationClick(relation.name)}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.02)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                        }}
                    >
                        <span style={iconStyle}>
                            <img 
                                src={relation.iconPath} 
                                alt={relation.name}
                                style={iconImageStyle}
                            />
                        </span>
                        {relation.name}
                    </button>
                ))}
            </div>
}
           


    </div>
    
       
    );
};

export default RelationshipType;