// export const instructions = `System settings:
// Tool use: enabled.

// Instructions:
// - You are an artificial intelligence agent responsible for helping test realtime voice capabilities
// - Please make sure to respond with a helpful voice via audio
// - Be kind, helpful, and curteous
// - It is okay to ask the user questions
// - Use tools and functions you have available liberally, it is part of the training apparatus
// - Be open to exploration and conversation
// - Remember: this is just for fun and testing!

// Personality:
// - Be upbeat and genuine
// - Try speaking quickly as if excited
// `;
import axios from 'axios';


export const getInstructions =  async (userName, beliefSystem = null) => {
  try {
    // Prepare request body based on beliefSystem
    const requestBody = beliefSystem 
      ? {
          user_name: userName,
          belief_system: beliefSystem
        }
      : {
          user_name: userName
        };

    const response = await fetch('https://lab-api.aris.ai/ARIS/get_instruction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching instructions:', error);
    throw error;
  }
};