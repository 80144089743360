import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext.js';
import SituationPopup from './SituationPopup.js';
import ReactMarkdown from 'react-markdown';

import { 
    Heading, 
    Text, 
    UnorderedList, 
    ListItem, 
    Box 
  } from '@chakra-ui/react';

  


const ResponsePopup = ({ isOpen, onClose, streamingContent }) => {
    if (!isOpen) return null;
    const textColor = "black";
    const renderers = {
        h1: ({ children }) => (
            <Heading as="h1" size="xl" color={textColor} fontWeight="bold" mb={2}>
                {children}
            </Heading>
        ),
        h2: ({ children }) => (
            <Heading as="h2" size="lg" color={textColor} fontWeight="bold" mb={2}>
                {children}
            </Heading>
        ),
        h3: ({ children }) => (
            <Heading as="h3" size="md" color={textColor} fontWeight="bold" mb={2}>
                {children}
            </Heading>
        ),
        p: ({ children }) => (
            <Text fontSize="md" color={textColor} mb={2}>
                {children}
            </Text>
        ),
        strong: ({ children }) => (
            <Text as="strong" fontWeight="bold" color={textColor} mb={2}>
                {children}
            </Text>
        ),
        ul: ({ children }) => (
            <UnorderedList pl={5} mb={2} styleType="disc" color={textColor}>
                {children}
            </UnorderedList>
        ),
        li: ({ children }) => (
            <ListItem fontSize="md" color={textColor} mb={1}>
                {children}
            </ListItem>
        ),
        br: () => <Box height="1rem" /> // Ensures new line rendering
    };
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '80%',
          maxWidth: '700px',
          maxHeight: '80vh',
          position: 'relative',
          overflow: 'auto'
        }}>
          <button 
            onClick={onClose}
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
              padding: '5px'
            }}
          >
            ×
          </button>
          <div 
  style={{ 
    marginTop: '20px', 
    color: 'black', 
    fontSize: '15px', // Adjust the text size
    lineHeight: '1.7' // Adjust the line spacing
  }}
>
  <ReactMarkdown components={renderers}>{streamingContent}</ReactMarkdown>
</div>
        </div>
      </div>
    );
  };
  

const RelationshipTopic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const relationship = location.state?.relationship;
    const relationshipData = location.state?.relationshipData;
    const relationshipRaw = location.state?.rawData;
    const [selectedName, setSelectedName] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const[userSelected,setUserSelected]=useState(false);
    const { user } = useUser();
    const { selectedBeliefSystem ,setSelectedBeliefSystem} = useUser();

    const [showSituationPopup, setShowSituationPopup] = useState(false);

    const colleagueNameList = ['Vira', 'Demetra', 'Demo', 'Niko'];
    const colleagueNames = colleagueNameList.filter(name => name !== user);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [streamingContent, setStreamingContent] = useState('');
    const [isUserUnderPressure, setIsUserUnderPressure] = useState(false);
  const [isColleagueUnderPressure, setIsColleagueUnderPressure] = useState(false);


     // Toggle button styles
     const toggleContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginTop: '20px',
      };
    
      const toggleLabelStyle = {
        marginRight: '10px',
        fontSize: '16px',
      };
    
      const toggleButtonStyle = {
        position: 'relative',
        width: '60px',
        height: '30px',
        backgroundColor: '#ccc',
        borderRadius: '15px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      };
    
      const toggleButtonActiveStyle = {
        ...toggleButtonStyle,
        backgroundColor: '#69b3c1',
      };
    
      const toggleSwitchStyle = (isActive) => ({
        position: 'absolute',
        top: '2px',
        left: isActive ? '30px' : '2px',
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        backgroundColor: 'white',
        transition: 'left 0.3s',
      });
    
      // Toggle button handler
      const handleUserPressureToggle = () => {
        setIsUserUnderPressure(!isUserUnderPressure);
      };
    
      const handleColleaguePressureToggle = () => {
        setIsColleagueUnderPressure(!isColleagueUnderPressure);
      };
    
  
    const handleProceed = async (chatHistory) => {
      try {
        setIsPopupOpen(true);
        setStreamingContent('');
  
        const response = await fetch('https://lab-api.aris.ai/ARIS/relationship', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ALqt8T93LBDcsDfFBfTI79V2ADMr5LUJHu7rPFsOgmBmyRJQf9UPblA2W40Rii2awFEagGtOFid74TuXbJrJSo8LCsbelVThaX81jzZArDSa4Ol2wjUirUM` // or however you store your token
          },
          body: JSON.stringify({
            name: user, 
            partner_name: relationshipData[selectedName],
            relation:selectedName,
            relationship: relationship,
            topic: selectedTopic,
            u1_Pstatus: isUserUnderPressure,
            u2_Pstatus: isColleagueUnderPressure,
            problem_description: chatHistory?.length > 4 ? chatHistory : [],
            belief_system: selectedBeliefSystem||{}
        
  
          })
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const text = decoder.decode(value);
          setStreamingContent(prevContent => prevContent + text);
        }
  
      } catch (error) {
        console.error('Error:', error);
        setStreamingContent('Error occurred while fetching data');
      }
    };
    const handleProceedClick = () => {
        setShowSituationPopup(true);
      };
    

    const relationshipTopics ={


      "Boyfriend":{
              "Build Trust": "Learn how to build and maintain trust by understanding your and your partner's fundamental needs and conflict management styles, ensuring actions that resonate with both of your personalities.",
              "Communicate Effectively": "Tailor your communication style to match both your and your partner's processing preferences, leading to clearer, more effective Interactions that align with how you both prefer to receive and process information.",
              "Manage Expectations": "Align your expectations by considering how each of you makes decisions and what motivates you, fostering a relationship built on mutual understanding and realistic goals.",
              "Resolve Conflict": "Use your insights into each other's conflict management styles to navigate disputes with empathy and understanding, ensuring that both parties feel heard and respected.",
              "Disagree with Empathy": "Leverage your knowledge of how each of you processes emotions and makes decisions to approach disagreements in a way that strengthens, rather than strains, your connection.",
              "Apologize": "Offer sincere apologies that resonate with your partner's fundamental needs and emotional processing style, facilitating deeper emotional healing and relationship repair.",
              "Set Boundaries": "Establish boundaries that respect both your needs for security and control, ensuring a balanced and healthy relationship dynamic.",
              "Balance Independence and Togetherness": "Use your understanding of motivational drivers to balance personal space and shared experiences in a way that satisfies both partners.",
              "Express Affection": "Tailor your expressions of affection to what truly resonates with your partner's emotional and psychological needs, ensuring they feel valued and appreciated.",
              "Navigate Commitment": "Approach discussions about commitment with insights into how each of you processes long-term decisions, creating a path forward that feels comfortable and secure for both"
      },
      "Girlfriend":{
              "Build Trust": "Learn how to build and maintain trust by understanding your and your partner's fundamental needs and conflict management styles, ensuring actions that resonate with both of your personalities.",
              "Communicate Effectively": "Tailor your communication style to match both your and your partner's processing preferences, leading to clearer, more effective Interactions that align with how you both prefer to receive and process information.",
              "Manage Expectations": "Align your expectations by considering how each of you makes decisions and what motivates you, fostering a relationship built on mutual understanding and realistic goals.",
              "Resolve Conflict": "Use your insights into each other's conflict management styles to navigate disputes with empathy and understanding, ensuring that both parties feel heard and respected.",
              "Disagree with Empathy": "Leverage your knowledge of how each of you processes emotions and makes decisions to approach disagreements in a way that strengthens, rather than strains, your connection.",
              "Apologize": "Offer sincere apologies that resonate with your partner's fundamental needs and emotional processing style, facilitating deeper emotional healing and relationship repair.",
              "Set Boundaries": "Establish boundaries that respect both your needs for security and control, ensuring a balanced and healthy relationship dynamic.",
              "Balance Independence and Togetherness": "Use your understanding of motivational drivers to balance personal space and shared experiences in a way that satisfies both partners.",
              "Express Affection": "Tailor your expressions of affection to what truly resonates with your partner's emotional and psychological needs, ensuring they feel valued and appreciated.",
              "Navigate Commitment": "Approach discussions about commitment with insights into how each of you processes long-term decisions, creating a path forward that feels comfortable and secure for both"
      },
      "Partner":{
              "Build Trust": "Learn how to build and maintain trust by understanding your and your partner's fundamental needs and conflict management styles, ensuring actions that resonate with both of your personalities.",
              "Communicate Effectively": "Tailor your communication style to match both your and your partner's processing preferences, leading to clearer, more effective Interactions that align with how you both prefer to receive and process information.",
              "Manage Expectations": "Align your expectations by considering how each of you makes decisions and what motivates you, fostering a relationship built on mutual understanding and realistic goals.",
              "Resolve Conflict": "Use your insights into each other's conflict management styles to navigate disputes with empathy and understanding, ensuring that both parties feel heard and respected.",
              "Disagree with Empathy": "Leverage your knowledge of how each of you processes emotions and makes decisions to approach disagreements in a way that strengthens, rather than strains, your connection.",
              "Apologize": "Offer sincere apologies that resonate with your partner's fundamental needs and emotional processing style, facilitating deeper emotional healing and relationship repair.",
              "Set Boundaries": "Establish boundaries that respect both your needs for security and control, ensuring a balanced and healthy relationship dynamic.",
              "Balance Independence and Togetherness": "Use your understanding of motivational drivers to balance personal space and shared experiences in a way that satisfies both partners.",
              "Express Affection": "Tailor your expressions of affection to what truly resonates with your partner's emotional and psychological needs, ensuring they feel valued and appreciated.",
              "Navigate Commitment": "Approach discussions about commitment with insights into how each of you processes long-term decisions, creating a path forward that feels comfortable and secure for both"
      },
      "Husband":{
              "Build Trust": "Strengthen trust by understanding and respecting each other's fundamental needs and pressure responses, ensuring that your actions consistently align with your partner's expectations.",
              "Communicate Effectively": "Improve communication by adapting your style to complement your partner’s processing and decision-making preferences, resulting in more effective and harmonious interactions.",
              "Resolve Conflict": "Address conflicts with a deep understanding of how both you and your partner manage stress and disagreements, leading to more constructive and lasting resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by considering how each of you perceives and responds to emotional stress, fostering a deeper connection even in moments of discord.",
              "Apologize": "Deliver apologies that are meaningful to your partner by addressing their specific needs for validation, control, or security, leading to quicker reconciliation and stronger emotional bonds.",
              "Manage Finances Together": "Approach financial decisions with a shared understanding of how each of you processes risk and values security, ensuring that both partners feel confident and supported.",
              "Support Life Changes": "Offer support during life transitions by leveraging insights into what drives and motivates each of you, making sure that your partner feels understood and backed during challenging times.",
              "Maintain Intimacy": "Keep intimacy alive by catering to each other's emotional and psychological needs, ensuring that both partners feel valued and connected.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each partner's strengths and stress points, promoting a balanced and cooperative household.",
              "Nurture Long-term Connection": "Continually invest in your relationship by recognizing and acting on the fundamental needs and emotional drivers that keep your connection strong over time."
          },
      "Wife":{
              "Build Trust": "Strengthen trust by understanding and respecting each other's fundamental needs and pressure responses, ensuring that your actions consistently align with your partner's expectations.",
              "Communicate Effectively": "Improve communication by adapting your style to complement your partner’s processing and decision-making preferences, resulting in more effective and harmonious interactions.",
              "Resolve Conflict": "Address conflicts with a deep understanding of how both you and your partner manage stress and disagreements, leading to more constructive and lasting resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by considering how each of you perceives and responds to emotional stress, fostering a deeper connection even in moments of discord.",
              "Apologize": "Deliver apologies that are meaningful to your partner by addressing their specific needs for validation, control, or security, leading to quicker reconciliation and stronger emotional bonds.",
              "Manage Finances Together": "Approach financial decisions with a shared understanding of how each of you processes risk and values security, ensuring that both partners feel confident and supported.",
              "Support Life Changes": "Offer support during life transitions by leveraging insights into what drives and motivates each of you, making sure that your partner feels understood and backed during challenging times.",
              "Maintain Intimacy": "Keep intimacy alive by catering to each other's emotional and psychological needs, ensuring that both partners feel valued and connected.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each partner's strengths and stress points, promoting a balanced and cooperative household.",
              "Nurture Long-term Connection": "Continually invest in your relationship by recognizing and acting on the fundamental needs and emotional drivers that keep your connection strong over time."
          },
      "Partner":{
              "Build Trust": "Strengthen trust by understanding and respecting each other's fundamental needs and pressure responses, ensuring that your actions consistently align with your partner's expectations.",
              "Communicate Effectively": "Improve communication by adapting your style to complement your partner’s processing and decision-making preferences, resulting in more effective and harmonious interactions.",
              "Resolve Conflict": "Address conflicts with a deep understanding of how both you and your partner manage stress and disagreements, leading to more constructive and lasting resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by considering how each of you perceives and responds to emotional stress, fostering a deeper connection even in moments of discord.",
              "Apologize": "Deliver apologies that are meaningful to your partner by addressing their specific needs for validation, control, or security, leading to quicker reconciliation and stronger emotional bonds.",
              "Manage Finances Together": "Approach financial decisions with a shared understanding of how each of you processes risk and values security, ensuring that both partners feel confident and supported.",
              "Support Life Changes": "Offer support during life transitions by leveraging insights into what drives and motivates each of you, making sure that your partner feels understood and backed during challenging times.",
              "Maintain Intimacy": "Keep intimacy alive by catering to each other's emotional and psychological needs, ensuring that both partners feel valued and connected.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each partner's strengths and stress points, promoting a balanced and cooperative household.",
              "Nurture Long-term Connection": "Continually invest in your relationship by recognizing and acting on the fundamental needs and emotional drivers that keep your connection strong over time."
          },
 
      "Son":{
              "Build Trust": "Establish a trusting relationship by understanding how your child’s personality and motivational drivers influence their behavior, ensuring your actions resonate with their needs.",
              "Communicate Effectively": "Adapt your communication to match your child’s processing style, ensuring they understand and feel understood in conversations with you.",
              "Provide Guidance": "Offer guidance that aligns with your child’s natural decision-making style, helping them navigate life’s challenges with confidence and clarity.",
              "Encourage Independence": "Support your child’s independence by recognizing their need for autonomy and control, providing the right balance of guidance and freedom.",
              "Foster Emotional Intelligence": "Tailor your approach to fostering emotional intelligence by understanding how your child processes emotions and stress, helping them develop resilience and empathy.",
              "Resolve Conflict": "Address conflicts with your child in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Set and Enforce Boundaries": "Establish boundaries that consider your child’s need for security or freedom, creating a structured environment that they can thrive in.",
              "Motivate": "Inspire your child by tapping into their personal motivational drivers, ensuring that your encouragement resonates and pushes them towards their goals.",
              "Teach Responsibility": "Instill a sense of responsibility in your child by understanding what drives their behavior and tailoring your expectations to match.",
              "Navigate Transitions": "Guide your child through significant life transitions with sensitivity to how they process change and stress, ensuring they feel supported and understood."
          },
      "Daughter":{
              "Build Trust": "Establish a trusting relationship by understanding how your child’s personality and motivational drivers influence their behavior, ensuring your actions resonate with their needs.",
              "Communicate Effectively": "Adapt your communication to match your child’s processing style, ensuring they understand and feel understood in conversations with you.",
              "Provide Guidance": "Offer guidance that aligns with your child’s natural decision-making style, helping them navigate life’s challenges with confidence and clarity.",
              "Encourage Independence": "Support your child’s independence by recognizing their need for autonomy and control, providing the right balance of guidance and freedom.",
              "Foster Emotional Intelligence": "Tailor your approach to fostering emotional intelligence by understanding how your child processes emotions and stress, helping them develop resilience and empathy.",
              "Resolve Conflict": "Address conflicts with your child in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Set and Enforce Boundaries": "Establish boundaries that consider your child’s need for security or freedom, creating a structured environment that they can thrive in.",
              "Motivate": "Inspire your child by tapping into their personal motivational drivers, ensuring that your encouragement resonates and pushes them towards their goals.",
              "Teach Responsibility": "Instill a sense of responsibility in your child by understanding what drives their behavior and tailoring your expectations to match.",
              "Navigate Transitions": "Guide your child through significant life transitions with sensitivity to how they process change and stress, ensuring they feel supported and understood."
          },
      "Father":{
              "Build Trust": "Strengthen your relationship with your parents by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and understood.",
              "Communicate Effectively": "Communicate with your parents in a manner that resonates with their preferred way of processing information, ensuring clarity and reducing misunderstandings.",
              "Show Appreciation": "Express appreciation in a way that truly resonates with your parents’ emotional needs, ensuring they feel valued and acknowledged.",
              "Resolve Conflict": "Navigate conflicts with your parents by considering how they handle stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Approach disagreements with empathy by understanding your parents’ decision-making styles, ensuring that discussions are respectful and productive.",
              "Seek and Give Support": "Offer and ask for support in ways that align with your parents’ motivational drivers, ensuring that your interactions are meaningful and supportive.",
              "Respect Parental Boundaries": "Understand and honor your parents’ boundaries by recognizing their need for control or security, fostering a healthy and respectful relationship.",
              "Navigate Life Changes": "Help your parents through life transitions by understanding how they process stress and change, ensuring they feel supported and valued.",
              "Apologize": "Offer apologies that address your parents’ specific needs for validation, ensuring that reconciliation is sincere and effective.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected."
          },
      "Mother":{
              "Build Trust": "Strengthen your relationship with your parents by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and understood.",
              "Communicate Effectively": "Communicate with your parents in a manner that resonates with their preferred way of processing information, ensuring clarity and reducing misunderstandings.",
              "Show Appreciation": "Express appreciation in a way that truly resonates with your parents’ emotional needs, ensuring they feel valued and acknowledged.",
              "Resolve Conflict": "Navigate conflicts with your parents by considering how they handle stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Approach disagreements with empathy by understanding your parents’ decision-making styles, ensuring that discussions are respectful and productive.",
              "Seek and Give Support": "Offer and ask for support in ways that align with your parents’ motivational drivers, ensuring that your interactions are meaningful and supportive.",
              "Respect Parental Boundaries": "Understand and honor your parents’ boundaries by recognizing their need for control or security, fostering a healthy and respectful relationship.",
              "Navigate Life Changes": "Help your parents through life transitions by understanding how they process stress and change, ensuring they feel supported and valued.",
              "Apologize": "Offer apologies that address your parents’ specific needs for validation, ensuring that reconciliation is sincere and effective.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected."
          },
      "Sister": {
              "Build Trust": "Develop a stronger sibling bond by acting in ways that align with your sibling’s fundamental needs and motivational drivers, ensuring they feel supported and understood.",
              "Communicate Effectively": "Tailor your communication style to match your sibling’s processing preferences, ensuring clear and effective exchanges.",
              "Resolve Conflict": "Address sibling conflicts with a deep understanding of how each of you manages stress and disagreements, leading to more harmonious resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your sibling processes emotions and makes decisions, fostering a closer and more supportive relationship.",
              "Support Each Other": "Offer support that resonates with your sibling’s needs and motivational drivers, ensuring they feel genuinely cared for.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each sibling’s strengths and stress points, promoting fairness and cooperation.",
              "Respect Boundaries": "Understand and respect your sibling’s boundaries by recognizing their needs for control or independence, ensuring a healthy and respectful relationship.",
              "Maintain Family Bonds": "Invest in your sibling relationship by recognizing and acting on the emotional and psychological needs that keep you connected.",
              "Celebrate Successes Together": "Acknowledge and celebrate each other’s achievements in a way that resonates with both of your motivational drivers, strengthening your bond.",
              "Navigate Childhood Rivalry": "Address and move past childhood conflicts with a deeper understanding of how each of you processes stress and emotions, building a stronger sibling connection."
          
      },
      "Brother": {
              "Build Trust": "Develop a stronger sibling bond by acting in ways that align with your sibling’s fundamental needs and motivational drivers, ensuring they feel supported and understood.",
              "Communicate Effectively": "Tailor your communication style to match your sibling’s processing preferences, ensuring clear and effective exchanges.",
              "Resolve Conflict": "Address sibling conflicts with a deep understanding of how each of you manages stress and disagreements, leading to more harmonious resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your sibling processes emotions and makes decisions, fostering a closer and more supportive relationship.",
              "Support Each Other": "Offer support that resonates with your sibling’s needs and motivational drivers, ensuring they feel genuinely cared for.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each sibling’s strengths and stress points, promoting fairness and cooperation.",
              "Respect Boundaries": "Understand and respect your sibling’s boundaries by recognizing their needs for control or independence, ensuring a healthy and respectful relationship.",
              "Maintain Family Bonds": "Invest in your sibling relationship by recognizing and acting on the emotional and psychological needs that keep you connected.",
              "Celebrate Successes Together": "Acknowledge and celebrate each other’s achievements in a way that resonates with both of your motivational drivers, strengthening your bond.",
              "Navigate Childhood Rivalry": "Address and move past childhood conflicts with a deeper understanding of how each of you processes stress and emotions, building a stronger sibling connection."
  
      },
      "Half-Sibling": {
              "Build Trust": "Develop a stronger sibling bond by acting in ways that align with your sibling’s fundamental needs and motivational drivers, ensuring they feel supported and understood.",
              "Communicate Effectively": "Tailor your communication style to match your sibling’s processing preferences, ensuring clear and effective exchanges.",
              "Resolve Conflict": "Address sibling conflicts with a deep understanding of how each of you manages stress and disagreements, leading to more harmonious resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your sibling processes emotions and makes decisions, fostering a closer and more supportive relationship.",
              "Support Each Other": "Offer support that resonates with your sibling’s needs and motivational drivers, ensuring they feel genuinely cared for.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each sibling’s strengths and stress points, promoting fairness and cooperation.",
              "Respect Boundaries": "Understand and respect your sibling’s boundaries by recognizing their needs for control or independence, ensuring a healthy and respectful relationship.",
              "Maintain Family Bonds": "Invest in your sibling relationship by recognizing and acting on the emotional and psychological needs that keep you connected.",
              "Celebrate Successes Together": "Acknowledge and celebrate each other’s achievements in a way that resonates with both of your motivational drivers, strengthening your bond.",
              "Navigate Childhood Rivalry": "Address and move past childhood conflicts with a deeper understanding of how each of you processes stress and emotions, building a stronger sibling connection."
          
      },
      "Cousin": {
              "Build Trust": "Develop a stronger sibling bond by acting in ways that align with your sibling’s fundamental needs and motivational drivers, ensuring they feel supported and understood.",
              "Communicate Effectively": "Tailor your communication style to match your sibling’s processing preferences, ensuring clear and effective exchanges.",
              "Resolve Conflict": "Address sibling conflicts with a deep understanding of how each of you manages stress and disagreements, leading to more harmonious resolutions.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your sibling processes emotions and makes decisions, fostering a closer and more supportive relationship.",
              "Support Each Other": "Offer support that resonates with your sibling’s needs and motivational drivers, ensuring they feel genuinely cared for.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each sibling’s strengths and stress points, promoting fairness and cooperation.",
              "Respect Boundaries": "Understand and respect your sibling’s boundaries by recognizing their needs for control or independence, ensuring a healthy and respectful relationship.",
              "Maintain Family Bonds": "Invest in your sibling relationship by recognizing and acting on the emotional and psychological needs that keep you connected.",
              "Celebrate Successes Together": "Acknowledge and celebrate each other’s achievements in a way that resonates with both of your motivational drivers, strengthening your bond.",
              "Navigate Childhood Rivalry": "Address and move past childhood conflicts with a deeper understanding of how each of you processes stress and emotions, building a stronger sibling connection."
          
      },
      "Grandmother" : {
              "Build Trust": "Foster a deeper relationship by acting in ways that align with your grandparents’ fundamental needs and emotional drivers, ensuring they feel valued and respected.",
              "Communicate Effectively": "Adapt your communication style to match your grandparents’ processing preferences, ensuring clear and meaningful interactions.",
              "Share Wisdom and Experience": "Encourage your grandparents to share their experiences in a way that aligns with their motivational drivers, ensuring they feel valued and appreciated.",
              "Provide Emotional Support": "Offer emotional support that resonates with your grandparents’ needs, helping them feel comforted and understood.",
              "Respect Boundaries": "Understand and honor your grandparents’ boundaries by recognizing their needs for security or control, fostering a respectful and supportive relationship.",
              "Nurture Family Traditions": "Participate in and preserve family traditions in a way that resonates with your grandparents’ emotional needs, ensuring they feel connected to their heritage.",
              "Foster Intergenerational Connection": "Engage in activities that bridge the generational gap by understanding and respecting each other's processing styles and needs.",
              "Resolve Family Conflicts": "Help mediate family disputes with insights into how your grandparents handle conflict, ensuring peaceful and respectful resolutions.",
              "Offer Guidance with Sensitivity": "Provide advice in a way that aligns with your grandparents’ emotional and psychological needs, ensuring they feel supported and respected.",
              "Support Changing Family Dynamics": "Help your grandparents navigate changes in the family by understanding their needs for security and stability, ensuring they feel valued and included."
          },
      "Grandfather": {
              "Build Trust": "Foster a deeper relationship by acting in ways that align with your grandparents’ fundamental needs and emotional drivers, ensuring they feel valued and respected.",
              "Communicate Effectively": "Adapt your communication style to match your grandparents’ processing preferences, ensuring clear and meaningful interactions.",
              "Share Wisdom and Experience": "Encourage your grandparents to share their experiences in a way that aligns with their motivational drivers, ensuring they feel valued and appreciated.",
              "Provide Emotional Support": "Offer emotional support that resonates with your grandparents’ needs, helping them feel comforted and understood.",
              "Respect Boundaries": "Understand and honor your grandparents’ boundaries by recognizing their needs for security or control, fostering a respectful and supportive relationship.",
              "Nurture Family Traditions": "Participate in and preserve family traditions in a way that resonates with your grandparents’ emotional needs, ensuring they feel connected to their heritage.",
              "Foster Intergenerational Connection": "Engage in activities that bridge the generational gap by understanding and respecting each other's processing styles and needs.",
              "Resolve Family Conflicts": "Help mediate family disputes with insights into how your grandparents handle conflict, ensuring peaceful and respectful resolutions.",
              "Offer Guidance with Sensitivity": "Provide advice in a way that aligns with your grandparents’ emotional and psychological needs, ensuring they feel supported and respected.",
              "Support Changing Family Dynamics": "Help your grandparents navigate changes in the family by understanding their needs for security and stability, ensuring they feel valued and included."
          },
      "Grandson": {
              "Build Trust": "Strengthen your relationship with your grandchildren by acting in ways that align with their fundamental needs and motivational drivers, ensuring they feel valued and respected.",
              "Communicate Effectively": "Tailor your communication style to match your grandchildren’s processing preferences, ensuring clear and effective interactions.",
              "Show Appreciation and Respect": "Express appreciation in a way that resonates with your grandchildren’s emotional needs, ensuring they feel valued and understood.",
              "Maintain Regular Contact": "Keep in touch with your grandchildren in a way that aligns with their preferred communication styles, fostering a strong and supportive relationship.",
              "Share Life Updates": "Keep your grandchildren informed about your life in a way that aligns with their processing and motivational preferences, ensuring they feel connected and involved.",
              "Seek Advice and Wisdom": "Encourage your grandchildren to seek your advice by understanding their decision-making and motivational drivers, ensuring they feel supported and guided.",
              "Involve Them in Family Events": "Include your grandchildren in family gatherings in a way that resonates with their emotional needs, ensuring they feel valued and connected.",
              "Provide Emotional Support": "Offer emotional support that aligns with your grandchildren’s needs, helping them feel comforted and understood.",
              "Respect Their Autonomy": "Understand and respect your grandchildren’s need for independence by recognizing their fundamental needs, fostering a respectful and supportive relationship.",
              "Assist with Technology and Modern Challenges": "Help your grandchildren navigate technology and modern societal changes by understanding their processing preferences, ensuring they feel supported and empowered."
          
      },
      "Granddaughter": {
              "Build Trust": "Strengthen your relationship with your grandchildren by acting in ways that align with their fundamental needs and motivational drivers, ensuring they feel valued and respected.",
              "Communicate Effectively": "Tailor your communication style to match your grandchildren’s processing preferences, ensuring clear and effective interactions.",
              "Show Appreciation and Respect": "Express appreciation in a way that resonates with your grandchildren’s emotional needs, ensuring they feel valued and understood.",
              "Maintain Regular Contact": "Keep in touch with your grandchildren in a way that aligns with their preferred communication styles, fostering a strong and supportive relationship.",
              "Share Life Updates": "Keep your grandchildren informed about your life in a way that aligns with their processing and motivational preferences, ensuring they feel connected and involved.",
              "Seek Advice and Wisdom": "Encourage your grandchildren to seek your advice by understanding their decision-making and motivational drivers, ensuring they feel supported and guided.",
              "Involve Them in Family Events": "Include your grandchildren in family gatherings in a way that resonates with their emotional needs, ensuring they feel valued and connected.",
              "Provide Emotional Support": "Offer emotional support that aligns with your grandchildren’s needs, helping them feel comforted and understood.",
              "Respect Their Autonomy": "Understand and respect your grandchildren’s need for independence by recognizing their fundamental needs, fostering a respectful and supportive relationship.",
              "Assist with Technology and Modern Challenges": "Help your grandchildren navigate technology and modern societal changes by understanding their processing preferences, ensuring they feel supported and empowered."
  
          },
      "Uncle":  {
              "Build Trust": "Foster deeper family bonds by acting in ways that align with each member’s fundamental needs and processing styles, ensuring everyone feels valued and understood.",
              "Communicate Effectively": "Tailor your communication style to match each family member’s preferences, ensuring clear and effective exchanges that strengthen family ties.",
              "Respect Individual Differences": "Acknowledge and value each family member’s unique qualities by understanding their motivational drivers and emotional needs, fostering a more inclusive family environment.",
              "Resolve Conflict": "Address family disputes with a deep understanding of how each member manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Navigate family disagreements with empathy by understanding each member’s decision-making and emotional processing styles, fostering a closer and more supportive relationship.",
              "Support Through Challenges": "Offer support that resonates with each family member’s needs and motivational drivers, ensuring they feel genuinely cared for and supported.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each family member’s strengths and stress points, promoting fairness and cooperation.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected.",
              "Handle Family Traditions": "Honor and participate in family traditions in a way that aligns with each member’s emotional needs, ensuring a sense of continuity and belonging.",
              "Balance Family and Personal Needs": "Manage your time and energy in a way that meets both personal and family responsibilities, ensuring a balanced and harmonious family life."
          
      },
      "Aunt":  {
              "Build Trust": "Foster deeper family bonds by acting in ways that align with each member’s fundamental needs and processing styles, ensuring everyone feels valued and understood.",
              "Communicate Effectively": "Tailor your communication style to match each family member’s preferences, ensuring clear and effective exchanges that strengthen family ties.",
              "Respect Individual Differences": "Acknowledge and value each family member’s unique qualities by understanding their motivational drivers and emotional needs, fostering a more inclusive family environment.",
              "Resolve Conflict": "Address family disputes with a deep understanding of how each member manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Navigate family disagreements with empathy by understanding each member’s decision-making and emotional processing styles, fostering a closer and more supportive relationship.",
              "Support Through Challenges": "Offer support that resonates with each family member’s needs and motivational drivers, ensuring they feel genuinely cared for and supported.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each family member’s strengths and stress points, promoting fairness and cooperation.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected.",
              "Handle Family Traditions": "Honor and participate in family traditions in a way that aligns with each member’s emotional needs, ensuring a sense of continuity and belonging.",
              "Balance Family and Personal Needs": "Manage your time and energy in a way that meets both personal and family responsibilities, ensuring a balanced and harmonious family life."
          
      },
      
      "In-Laws":  {
              "Build Trust": "Foster deeper family bonds by acting in ways that align with each member’s fundamental needs and processing styles, ensuring everyone feels valued and understood.",
              "Communicate Effectively": "Tailor your communication style to match each family member’s preferences, ensuring clear and effective exchanges that strengthen family ties.",
              "Respect Individual Differences": "Acknowledge and value each family member’s unique qualities by understanding their motivational drivers and emotional needs, fostering a more inclusive family environment.",
              "Resolve Conflict": "Address family disputes with a deep understanding of how each member manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Navigate family disagreements with empathy by understanding each member’s decision-making and emotional processing styles, fostering a closer and more supportive relationship.",
              "Support Through Challenges": "Offer support that resonates with each family member’s needs and motivational drivers, ensuring they feel genuinely cared for and supported.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each family member’s strengths and stress points, promoting fairness and cooperation.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected.",
              "Handle Family Traditions": "Honor and participate in family traditions in a way that aligns with each member’s emotional needs, ensuring a sense of continuity and belonging.",
              "Balance Family and Personal Needs": "Manage your time and energy in a way that meets both personal and family responsibilities, ensuring a balanced and harmonious family life."
          
      },
      "Step-Relations":  {
              "Build Trust": "Foster deeper family bonds by acting in ways that align with each member’s fundamental needs and processing styles, ensuring everyone feels valued and understood.",
              "Communicate Effectively": "Tailor your communication style to match each family member’s preferences, ensuring clear and effective exchanges that strengthen family ties.",
              "Respect Individual Differences": "Acknowledge and value each family member’s unique qualities by understanding their motivational drivers and emotional needs, fostering a more inclusive family environment.",
              "Resolve Conflict": "Address family disputes with a deep understanding of how each member manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Navigate family disagreements with empathy by understanding each member’s decision-making and emotional processing styles, fostering a closer and more supportive relationship.",
              "Support Through Challenges": "Offer support that resonates with each family member’s needs and motivational drivers, ensuring they feel genuinely cared for and supported.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each family member’s strengths and stress points, promoting fairness and cooperation.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected.",
              "Handle Family Traditions": "Honor and participate in family traditions in a way that aligns with each member’s emotional needs, ensuring a sense of continuity and belonging.",
              "Balance Family and Personal Needs": "Manage your time and energy in a way that meets both personal and family responsibilities, ensuring a balanced and harmonious family life."
          
      },
      "Other":{
              "Build Trust": "Foster deeper family bonds by acting in ways that align with each member’s fundamental needs and processing styles, ensuring everyone feels valued and understood.",
              "Communicate Effectively": "Tailor your communication style to match each family member’s preferences, ensuring clear and effective exchanges that strengthen family ties.",
              "Respect Individual Differences": "Acknowledge and value each family member’s unique qualities by understanding their motivational drivers and emotional needs, fostering a more inclusive family environment.",
              "Resolve Conflict": "Address family disputes with a deep understanding of how each member manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Navigate family disagreements with empathy by understanding each member’s decision-making and emotional processing styles, fostering a closer and more supportive relationship.",
              "Support Through Challenges": "Offer support that resonates with each family member’s needs and motivational drivers, ensuring they feel genuinely cared for and supported.",
              "Share Responsibilities": "Divide responsibilities in a way that reflects each family member’s strengths and stress points, promoting fairness and cooperation.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected.",
              "Handle Family Traditions": "Honor and participate in family traditions in a way that aligns with each member’s emotional needs, ensuring a sense of continuity and belonging.",
              "Balance Family and Personal Needs": "Manage your time and energy in a way that meets both personal and family responsibilities, ensuring a balanced and harmonious family life."
          
      },

      "Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Close Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Best Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Childhood Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Grammar School Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "High School Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "College Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Virtual Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Work Friend":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Other":  {
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
 
      "Colleague":  {
              "Communicate Effectively": "Tailor your communication style to align with your colleague’s processing preferences, ensuring clear and effective exchanges that enhance workplace collaboration.",
              "Collaborate": "Work together efficiently by understanding each other’s motivational drivers and decision-making styles, ensuring a productive and harmonious work environment.",
              "Build Trust": "Foster trust in the workplace by acting in ways that align with your colleague’s fundamental needs, ensuring they feel respected and valued.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your colleague’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Manage Professional Boundaries": "Maintain appropriate boundaries by recognizing each other’s needs for autonomy and security, fostering a respectful and supportive work relationship.",
              "Resolve Conflict": "Address workplace conflicts with a deep understanding of how each colleague manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Respect": "Navigate professional disagreements with respect by understanding each colleague’s decision-making and emotional processing styles, ensuring discussions remain productive.",
              "Foster Team Spirit": "Contribute to a positive and collaborative team environment by recognizing and acting on the motivational drivers that keep your team connected.",
              "Share Credit and Success": "Recognize and celebrate your colleagues’ contributions in a way that resonates with their motivational needs, ensuring a supportive and appreciative workplace culture.",
              "Handle Workplace Dynamics": "Navigate office politics and dynamics with insight into how each colleague processes stress and conflict, ensuring a balanced and effective work environment."
          
      },
     
      "Business Partner": {
        
              "Build Trust": "Strengthen your business partnership by acting in ways that align with your partner’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Tailor your communication style to match your partner’s preferences, ensuring clear and effective exchanges that enhance your business collaboration.",
              "Collaborate on Strategic Decisions":"Make business decisions together by understanding each partner’s decision-making and motivational drivers, ensuring that both of you feel confident and aligned.",
              "Resolve Conflict": "Address business disputes with a deep understanding of how each partner manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Align on Vision and Goals": "Ensure that both partners share the same vision for the business by understanding each other’s motivational drivers and long-term goals, fostering a strong and aligned partnership.",
              "Share Leadership Roles": "Distribute leadership responsibilities in a way that leverages each partner’s strengths and stress points, ensuring a balanced and effective business operation.",
              "Handle Financial Management": "Manage the business’s finances collaboratively and transparently by understanding each partner’s needs for security and control, ensuring both partners feel confident and secure.",
              "Navigate Power Dynamics": "Balance power and influence within the partnership by understanding each partner’s motivational drivers, ensuring a fair and respectful business relationship.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your partner’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Plan for Long-Term Success":"Work together to develop strategies that ensure the business’s long-term viability by understanding each partner’s decision-making and motivational drivers, ensuring sustained success."
          
      },
      "Investor": {
        
              "Build Trust": "Strengthen your relationship with your investor by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Vision and Goals": "Clearly articulate your business vision and objectives by understanding the investor’s decision-making and motivational drivers, ensuring they feel aligned and supportive of your goals.",
              "Provide Transparent Updates": "Keep the investor informed about the company’s progress and challenges by understanding their needs for security and control, ensuring they feel confident and secure in the investment.",
              "Seek Strategic Input": "Ask for the investor’s advice on key business decisions and strategies by understanding their decision-making and motivational drivers, ensuring they feel valued and motivated to invest in your success.",
              "Manage Financial Expectations": "Align with the investor on financial goals and performance metrics by understanding their decision-making and motivational drivers, ensuring both parties feel confident and secure in the investment.",
              "Collaborate on Growth Strategies": "Work together to develop strategies that drive business growth by understanding the investor’s decision-making and motivational drivers, ensuring both parties feel aligned and supportive of the business’s success.",
              "Address Investor Concerns": "Actively listen and respond to any concerns raised by the investor by understanding their needs for security and control, ensuring they feel confident and secure in the investment.",
              "Share Successes and Challenges": "Be open about both achievements and obstacles in the business by understanding the investor’s motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Demonstrate Accountability": "Take responsibility for business outcomes and decisions by understanding the investor’s needs for control and security, ensuring they feel confident and secure in the investment.",
              "Foster Long-term Partnership": "Focus on building a relationship that supports sustained business success by understanding the investor’s fundamental needs and processing styles, ensuring a strong and supportive partnership."
          
      },
      "Manager": {
              "Build Trust": "Strengthen your relationship with your manager by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Keep your manager informed about your progress and challenges by understanding their communication preferences, ensuring clear and effective exchanges.",
              "Provide Upward Feedback": "Offer constructive feedback to your manager by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to improved leadership.",
              "Present Ideas Clearly": "Share your ideas and suggestions in a clear and organized manner by understanding how your manager processes information, ensuring your input is valued and considered.",
              "Manage Expectations": "Align with your manager on priorities and deliverables by understanding their decision-making style, ensuring both of you feel confident and aligned.",
              "Resolve Conflict": "Address any disagreements with your manager calmly and professionally by understanding their conflict management style, ensuring a peaceful and productive resolution.",
              "Demonstrate Initiative": "Take proactive steps to contribute to your team’s success by understanding what drives your manager, ensuring your efforts are recognized and valued.",
              "Align with Team Goals": "Ensure your work supports the overall objectives of the team and organization by understanding your manager’s motivational drivers, ensuring both of you are working towards the same goals.",
              "Balance Workload Effectively": "Manage your tasks efficiently to meet deadlines and deliver quality work by understanding your manager’s expectations and processing style, ensuring a balanced and productive workload.",
              "Foster a Collaborative Environment": "Contribute to a positive and collaborative team culture by recognizing and acting on the motivational drivers that keep your team connected, ensuring a supportive and harmonious work environment."
          
      },
      "Supervisor": {
              "Build Trust": "Strengthen your relationship with your manager by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Keep your manager informed about your progress and challenges by understanding their communication preferences, ensuring clear and effective exchanges.",
              "Provide Upward Feedback": "Offer constructive feedback to your manager by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to improved leadership.",
              "Present Ideas Clearly": "Share your ideas and suggestions in a clear and organized manner by understanding how your manager processes information, ensuring your input is valued and considered.",
              "Manage Expectations": "Align with your manager on priorities and deliverables by understanding their decision-making style, ensuring both of you feel confident and aligned.",
              "Resolve Conflict": "Address any disagreements with your manager calmly and professionally by understanding their conflict management style, ensuring a peaceful and productive resolution.",
              "Demonstrate Initiative": "Take proactive steps to contribute to your team’s success by understanding what drives your manager, ensuring your efforts are recognized and valued.",
              "Align with Team Goals": "Ensure your work supports the overall objectives of the team and organization by understanding your manager’s motivational drivers, ensuring both of you are working towards the same goals.",
              "Balance Workload Effectively": "Manage your tasks efficiently to meet deadlines and deliver quality work by understanding your manager’s expectations and processing style, ensuring a balanced and productive workload.",
              "Foster a Collaborative Environment": "Contribute to a positive and collaborative team culture by recognizing and acting on the motivational drivers that keep your team connected, ensuring a supportive and harmonious work environment."
          
      },
      "Peer":  {
              "Communicate Effectively": "Tailor your communication style to align with your colleague’s processing preferences, ensuring clear and effective exchanges that enhance workplace collaboration.",
              "Collaborate": "Work together efficiently by understanding each other’s motivational drivers and decision-making styles, ensuring a productive and harmonious work environment.",
              "Build Trust": "Foster trust in the workplace by acting in ways that align with your colleague’s fundamental needs, ensuring they feel respected and valued.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your colleague’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Manage Professional Boundaries": "Maintain appropriate boundaries by recognizing each other’s needs for autonomy and security, fostering a respectful and supportive work relationship.",
              "Resolve Conflict": "Address workplace conflicts with a deep understanding of how each colleague manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Respect": "Navigate professional disagreements with respect by understanding each colleague’s decision-making and emotional processing styles, ensuring discussions remain productive.",
              "Foster Team Spirit": "Contribute to a positive and collaborative team environment by recognizing and acting on the motivational drivers that keep your team connected.",
              "Share Credit and Success": "Recognize and celebrate your colleagues’ contributions in a way that resonates with their motivational needs, ensuring a supportive and appreciative workplace culture.",
              "Handle Workplace Dynamics": "Navigate office politics and dynamics with insight into how each colleague processes stress and conflict, ensuring a balanced and effective work environment."
          
      },
      "Direct Report": 
          {
              "Build Trust": "Earn the trust of your direct reports by acting in ways that align with their fundamental needs and motivational drivers, ensuring they feel respected and valued.",
              "Communicate Effectively": "Provide clear instructions and maintain open lines of communication by understanding each direct report’s processing preferences, ensuring they feel supported and informed.",
              "Motivate and Inspire": "Encourage and inspire your team by understanding what drives each direct report, ensuring your leadership resonates and pushes them to perform at their best.",
              "Provide Constructive Feedback": "Offer feedback that resonates with each direct report’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Develop Talent": "Identify and nurture the skills and talents of your team members by understanding their decision-making and motivational drivers, ensuring they feel supported and developed.",
              "Resolve Conflict": "Mediate and resolve conflicts within your team by understanding how each direct report manages stress and disagreements, leading to a more peaceful and productive work environment.",
              "Delegate Effectively": "Assign tasks based on your team members’ strengths and development needs by understanding their processing styles and motivational drivers, ensuring tasks are completed efficiently and effectively.",
              "Foster a Positive Work Environment": "Create a work culture that promotes collaboration and well-being by recognizing and acting on the motivational drivers that keep your team connected.",
              "Set Clear Expectations": "Clearly communicate your expectations to avoid misunderstandings by understanding each direct report’s processing preferences, ensuring they feel informed and aligned.",
              "Recognize and Reward Achievement": "Acknowledge and reward your team’s successes in a way that resonates with their motivational drivers, ensuring they feel appreciated and motivated."
          
      },
      "Client": {
        
              "Build Trust": "Strengthen your business partnership by acting in ways that align with your partner’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Tailor your communication style to match your partner’s preferences, ensuring clear and effective exchanges that enhance your business collaboration.",
              "Collaborate on Strategic Decisions":"Make business decisions together by understanding each partner’s decision-making and motivational drivers, ensuring that both of you feel confident and aligned.",
              "Resolve Conflict": "Address business disputes with a deep understanding of how each partner manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Align on Vision and Goals": "Ensure that both partners share the same vision for the business by understanding each other’s motivational drivers and long-term goals, fostering a strong and aligned partnership.",
              "Share Leadership Roles": "Distribute leadership responsibilities in a way that leverages each partner’s strengths and stress points, ensuring a balanced and effective business operation.",
              "Handle Financial Management": "Manage the business’s finances collaboratively and transparently by understanding each partner’s needs for security and control, ensuring both partners feel confident and secure.",
              "Navigate Power Dynamics": "Balance power and influence within the partnership by understanding each partner’s motivational drivers, ensuring a fair and respectful business relationship.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your partner’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Plan for Long-Term Success":"Work together to develop strategies that ensure the business’s long-term viability by understanding each partner’s decision-making and motivational drivers, ensuring sustained success."
          
      },
      "Freelancer/Contractor": {
        
              "Build Trust": "Strengthen your business partnership by acting in ways that align with your partner’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Tailor your communication style to match your partner’s preferences, ensuring clear and effective exchanges that enhance your business collaboration.",
              "Collaborate on Strategic Decisions":"Make business decisions together by understanding each partner’s decision-making and motivational drivers, ensuring that both of you feel confident and aligned.",
              "Resolve Conflict": "Address business disputes with a deep understanding of how each partner manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Align on Vision and Goals": "Ensure that both partners share the same vision for the business by understanding each other’s motivational drivers and long-term goals, fostering a strong and aligned partnership.",
              "Share Leadership Roles": "Distribute leadership responsibilities in a way that leverages each partner’s strengths and stress points, ensuring a balanced and effective business operation.",
              "Handle Financial Management": "Manage the business’s finances collaboratively and transparently by understanding each partner’s needs for security and control, ensuring both partners feel confident and secure.",
              "Navigate Power Dynamics": "Balance power and influence within the partnership by understanding each partner’s motivational drivers, ensuring a fair and respectful business relationship.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your partner’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Plan for Long-Term Success":"Work together to develop strategies that ensure the business’s long-term viability by understanding each partner’s decision-making and motivational drivers, ensuring sustained success."
          
      },
      "Mentee": {
         
              "Build Trust": "Foster a strong mentoring relationship by acting in ways that align with your mentee’s fundamental needs and processing styles, ensuring they feel respected and supported.",
              "Communicate Effectively": "Provide clear and constructive feedback by understanding your mentee’s communication preferences, ensuring your guidance is received positively and leads to meaningful growth.",
              "Provide Guidance and Feedback": "Offer insights and advice that resonate with your mentee’s decision-making and motivational drivers, ensuring they feel supported and guided in their development.",
              "Motivate and Encourage": "Inspire your mentee to pursue their goals by understanding what drives them, ensuring your encouragement resonates and pushes them to achieve their full potential.",
              "Share Experience and Knowledge": "Pass on your expertise in a way that aligns with your mentee’s processing preferences, ensuring they feel empowered and informed.",
              "Help Set and Achieve Goals": "Work with your mentee to establish and achieve their professional or personal goals by understanding their decision-making and motivational drivers, ensuring they stay focused and motivated.",
              "Foster Growth and Development": "Support your mentee’s continuous learning and development by recognizing their strengths and areas for improvement, ensuring they feel valued and supported in their growth.",
              "Resolve Mentee Challenges": "Assist your mentee in overcoming any obstacles they encounter by understanding their conflict management style, ensuring they feel guided and supported through challenges.",
              "Build Confidence": "Encourage your mentee to believe in their abilities and potential by understanding their fundamental needs, ensuring they feel confident and motivated.",
              "Maintain Boundaries and Professionalism": "Establish and respect boundaries by understanding your mentee’s need for control or security, ensuring a healthy and respectful mentoring relationship."
          
      },
      "Mentor":  {
              "Build Trust": "Strengthen your mentoring relationship by acting in ways that align with your mentor’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Openly": "Share your goals, challenges, and progress with your mentor by understanding their communication preferences, ensuring clear and effective exchanges.",
              "Ask for Feedback": "Actively seek constructive feedback from your mentor by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to meaningful improvements.",
              "Show Appreciation": "Express gratitude for your mentor’s time and guidance in a way that resonates with their emotional needs, ensuring they feel valued and respected.",
              "Share Your Goals": "Clearly communicate your objectives by understanding your mentor’s decision-making style, ensuring they feel aligned and supportive of your aspirations.",
              "Seek Guidance": "Be proactive in asking for advice and support by understanding what drives your mentor, ensuring they feel valued and motivated to invest in your development.",
              "Demonstrate Willingness to Learn": "Show enthusiasm and a commitment to learning from your mentor by understanding their motivational drivers, ensuring they feel valued and respected.",
              "Respect Mentor’s Time": "Be mindful of your mentor’s time and schedule meetings accordingly by understanding their need for structure or flexibility, ensuring a respectful and supportive relationship.",
              "Provide Updates on Progress": "Keep your mentor informed about your achievements and areas for improvement by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your progress.",
              "Be Open to Challenges": "Embrace opportunities for growth, even when they push you out of your comfort zone, by understanding your mentor’s expectations and motivational drivers, ensuring a positive and productive mentoring experience."
          
      },
      "Other":
      {
              "Build Trust": "Strengthen your mentoring relationship by acting in ways that align with your mentor’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Openly": "Share your goals, challenges, and progress with your mentor by understanding their communication preferences, ensuring clear and effective exchanges.",
              "Ask for Feedback": "Actively seek constructive feedback from your mentor by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to meaningful improvements.",
              "Show Appreciation": "Express gratitude for your mentor’s time and guidance in a way that resonates with their emotional needs, ensuring they feel valued and respected.",
              "Share Your Goals": "Clearly communicate your objectives by understanding your mentor’s decision-making style, ensuring they feel aligned and supportive of your aspirations.",
              "Seek Guidance": "Be proactive in asking for advice and support by understanding what drives your mentor, ensuring they feel valued and motivated to invest in your development.",
              "Demonstrate Willingness to Learn": "Show enthusiasm and a commitment to learning from your mentor by understanding their motivational drivers, ensuring they feel valued and respected.",
              "Respect Mentor’s Time": "Be mindful of your mentor’s time and schedule meetings accordingly by understanding their need for structure or flexibility, ensuring a respectful and supportive relationship.",
              "Provide Updates on Progress": "Keep your mentor informed about your achievements and areas for improvement by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your progress.",
              "Be Open to Challenges": "Embrace opportunities for growth, even when they push you out of your comfort zone, by understanding your mentor’s expectations and motivational drivers, ensuring a positive and productive mentoring experience."
      },
  
  
  
      "College Roommate": {
         
              "Communicate Effectively":" Tailor your communication style to match your roommate’s preferences, ensuring clear and effective exchanges that enhance your living situation.",
              "Collaborate on Shared Spaces":" Work together on household tasks by understanding each other’s motivational drivers and decision-making styles, ensuring a harmonious living environment.",
              "Build Trust":" Foster trust within your shared living space by acting in ways that align with your roommate’s fundamental needs, ensuring they feel respected and valued.",
              "Resolve Conflict":" Address roommate conflicts by understanding how each of you manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Respect Privacy":" Honor each other’s personal space and boundaries by recognizing your roommate’s need for control or independence, ensuring a healthy and respectful living environment.",
              "Set House Rules":" Establish and agree on house rules that align with each roommate’s needs for order or flexibility, ensuring a harmonious and cooperative living arrangement.",
              "Share Responsibilities Fairly":" Divide household chores and responsibilities in a way that reflects each roommate’s strengths and stress points, promoting fairness and cooperation.",
              "Handle Financial Matters":" Manage shared expenses transparently and fairly by understanding each roommate’s needs for security and control, ensuring a stress-free financial arrangement.",
              "Disagree with Empathy":" Navigate disagreements with empathy by understanding how your roommate processes emotions and makes decisions, fostering a closer and more supportive living situation.",
              "Apologize":" Offer apologies that address your roommate’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation."
          
      },
      "Work Colleague Roommate": {
         
              "Communicate Effectively":" Tailor your communication style to match your roommate’s preferences, ensuring clear and effective exchanges that enhance your living situation.",
              "Collaborate on Shared Spaces":" Work together on household tasks by understanding each other’s motivational drivers and decision-making styles, ensuring a harmonious living environment.",
              "Build Trust":" Foster trust within your shared living space by acting in ways that align with your roommate’s fundamental needs, ensuring they feel respected and valued.",
              "Resolve Conflict":" Address roommate conflicts by understanding how each of you manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Respect Privacy":" Honor each other’s personal space and boundaries by recognizing your roommate’s need for control or independence, ensuring a healthy and respectful living environment.",
              "Set House Rules":" Establish and agree on house rules that align with each roommate’s needs for order or flexibility, ensuring a harmonious and cooperative living arrangement.",
              "Share Responsibilities Fairly":" Divide household chores and responsibilities in a way that reflects each roommate’s strengths and stress points, promoting fairness and cooperation.",
              "Handle Financial Matters":" Manage shared expenses transparently and fairly by understanding each roommate’s needs for security and control, ensuring a stress-free financial arrangement.",
              "Disagree with Empathy":" Navigate disagreements with empathy by understanding how your roommate processes emotions and makes decisions, fostering a closer and more supportive living situation.",
              "Apologize":" Offer apologies that address your roommate’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation."
          
      },
      "Long-term Partner Roommate": {
         
              "Communicate Effectively":" Tailor your communication style to match your roommate’s preferences, ensuring clear and effective exchanges that enhance your living situation.",
              "Collaborate on Shared Spaces":" Work together on household tasks by understanding each other’s motivational drivers and decision-making styles, ensuring a harmonious living environment.",
              "Build Trust":" Foster trust within your shared living space by acting in ways that align with your roommate’s fundamental needs, ensuring they feel respected and valued.",
              "Resolve Conflict":" Address roommate conflicts by understanding how each of you manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Respect Privacy":" Honor each other’s personal space and boundaries by recognizing your roommate’s need for control or independence, ensuring a healthy and respectful living environment.",
              "Set House Rules":" Establish and agree on house rules that align with each roommate’s needs for order or flexibility, ensuring a harmonious and cooperative living arrangement.",
              "Share Responsibilities Fairly":" Divide household chores and responsibilities in a way that reflects each roommate’s strengths and stress points, promoting fairness and cooperation.",
              "Handle Financial Matters":" Manage shared expenses transparently and fairly by understanding each roommate’s needs for security and control, ensuring a stress-free financial arrangement.",
              "Disagree with Empathy":" Navigate disagreements with empathy by understanding how your roommate processes emotions and makes decisions, fostering a closer and more supportive living situation.",
              "Apologize":" Offer apologies that address your roommate’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation."
          
      },
      "Short-term Roommate": {
         
              "Communicate Effectively":" Tailor your communication style to match your roommate’s preferences, ensuring clear and effective exchanges that enhance your living situation.",
              "Collaborate on Shared Spaces":" Work together on household tasks by understanding each other’s motivational drivers and decision-making styles, ensuring a harmonious living environment.",
              "Build Trust":" Foster trust within your shared living space by acting in ways that align with your roommate’s fundamental needs, ensuring they feel respected and valued.",
              "Resolve Conflict":" Address roommate conflicts by understanding how each of you manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Respect Privacy":" Honor each other’s personal space and boundaries by recognizing your roommate’s need for control or independence, ensuring a healthy and respectful living environment.",
              "Set House Rules":" Establish and agree on house rules that align with each roommate’s needs for order or flexibility, ensuring a harmonious and cooperative living arrangement.",
              "Share Responsibilities Fairly":" Divide household chores and responsibilities in a way that reflects each roommate’s strengths and stress points, promoting fairness and cooperation.",
              "Handle Financial Matters":" Manage shared expenses transparently and fairly by understanding each roommate’s needs for security and control, ensuring a stress-free financial arrangement.",
              "Disagree with Empathy":" Navigate disagreements with empathy by understanding how your roommate processes emotions and makes decisions, fostering a closer and more supportive living situation.",
              "Apologize":" Offer apologies that address your roommate’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation."
          
      },
      "Other": {
         
              "Communicate Effectively":" Tailor your communication style to match your roommate’s preferences, ensuring clear and effective exchanges that enhance your living situation.",
              "Collaborate on Shared Spaces":" Work together on household tasks by understanding each other’s motivational drivers and decision-making styles, ensuring a harmonious living environment.",
              "Build Trust":" Foster trust within your shared living space by acting in ways that align with your roommate’s fundamental needs, ensuring they feel respected and valued.",
              "Resolve Conflict":" Address roommate conflicts by understanding how each of you manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Respect Privacy":" Honor each other’s personal space and boundaries by recognizing your roommate’s need for control or independence, ensuring a healthy and respectful living environment.",
              "Set House Rules":" Establish and agree on house rules that align with each roommate’s needs for order or flexibility, ensuring a harmonious and cooperative living arrangement.",
              "Share Responsibilities Fairly":" Divide household chores and responsibilities in a way that reflects each roommate’s strengths and stress points, promoting fairness and cooperation.",
              "Handle Financial Matters":" Manage shared expenses transparently and fairly by understanding each roommate’s needs for security and control, ensuring a stress-free financial arrangement.",
              "Disagree with Empathy":" Navigate disagreements with empathy by understanding how your roommate processes emotions and makes decisions, fostering a closer and more supportive living situation.",
              "Apologize":" Offer apologies that address your roommate’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation."
          
      },

       "Assistant Coach": {
          
              "Motivate": "Inspire your athletes by understanding their individual motivational drivers, ensuring your encouragement resonates and pushes them to perform at their best.",
              "Communicate Effectively": "Tailor your communication style to match your athletes’ processing preferences, ensuring clear and effective coaching that leads to improved performance.",
              "Build Trust": "Foster a trusting coach-athlete relationship by acting in ways that align with your athletes’ fundamental needs, ensuring they feel supported and respected.",
              "Provide Targeted Feedback": "Offer feedback that resonates with each athlete’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Resolve Team Conflicts": "Address conflicts within the team by understanding how each athlete manages stress and disagreements, ensuring resolutions that strengthen team cohesion.",
              "Promote Teamwork": "Encourage collaboration and unity among team members by recognizing and acting on the motivational drivers that keep your team connected.",
              "Develop Mental Toughness": "Help your athletes build resilience by understanding their emotional processing styles, ensuring they are equipped to handle pressure and adversity.",
              "Encourage Sportsmanship": "Instill values of fairness and respect in your athletes by understanding their motivational drivers, ensuring they uphold the principles of sportsmanship.",
              "Set Goals": "Work with your athletes to set achievable goals that align with their individual motivational drivers, ensuring they stay focused and motivated.",
              "Handle Performance Pressure": "Support your athletes in managing stress by understanding their pressure responses, ensuring they remain calm and focused during competitions."
          
      },
      "Coach": {
  
              "Build Trust": "Strengthen your coaching relationship by acting in ways that align with your coach’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Goals Clearly": "Clearly articulate your objectives to help the coach tailor their guidance by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your aspirations.",
              "Provide Honest Feedback": "Share your thoughts on the coaching process by understanding your coach’s communication preferences, ensuring your feedback is received positively and leads to meaningful improvements.",
              "Be Open to New Perspectives": "Embrace the coach’s feedback and be willing to explore new ideas by understanding their decision-making and motivational drivers, ensuring you stay open-minded and receptive to growth.",
              "Demonstrate Commitment": "Show dedication to the coaching process by actively engaging in sessions and following through on recommendations, ensuring your coach feels valued and motivated to invest in your development.",
              "Share Challenges and Successes": "Keep your coach informed about your progress and any obstacles you face by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Respect the Coaching Process": "Follow through on the coach’s recommendations and exercises by understanding their motivational drivers, ensuring you stay aligned and committed to your growth.",
              "Seek Guidance on Leadership Growth": "Ask for specific advice on how to enhance your leadership skills by understanding your coach’s decision-making and motivational drivers, ensuring you receive tailored and effective guidance.",
              "Reflect on Insights": "Take time to reflect on the coach’s feedback and how it applies to your work by understanding your coach’s motivational drivers, ensuring you stay aligned and motivated to implement new strategies.",
              "Collaborate on Action Plans": "Work with your coach to develop and implement action plans that align with both your and your coach’s motivational drivers, ensuring you stay focused and motivated to achieve your goals."
          
      },
      "Athlete/Player": {
              "Communicate Effectively": "Adapt your communication style to match your coach’s preferences, ensuring clear and effective exchanges that enhance your training and performance.",
              "Provide Feedback": "Offer constructive feedback to your coach by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to improved coaching.",
              "Build Trust": "Foster a trusting coach-athlete relationship by acting in ways that align with your coach’s fundamental needs, ensuring they feel respected and valued.",
              "Motivate": "Demonstrate your commitment and dedication to training by understanding what drives your coach, ensuring they feel supported and motivated to invest in your development.",
              "Develop Discipline": "Follow your coach’s guidance by understanding their expectations and motivational drivers, ensuring you stay on track and achieve your goals.",
              "Support Personal Growth": "Be open to learning and growth by understanding how your coach processes feedback and makes decisions, ensuring a positive and productive training experience.",
              "Foster Team Collaboration": "Work well with teammates by understanding how each of you processes information and makes decisions, ensuring a harmonious and supportive team environment.",
              "Address Individual Needs": "Communicate any personal challenges to your coach by understanding how they process emotions and stress, ensuring you receive tailored support.",
              "Set and Achieve Goals": "Collaborate with your coach to set realistic goals that align with both your and your coach’s motivational drivers, ensuring you stay focused and motivated.",
              "Manage Stress and Performance Anxiety": "Use your coach’s strategies to handle pressure by understanding how they process stress and make decisions, ensuring you remain calm and focused during competitions."
          
      },
  
           "Trainer": {
          
              "Motivate": "Inspire your athletes by understanding their individual motivational drivers, ensuring your encouragement resonates and pushes them to perform at their best.",
              "Communicate Effectively": "Tailor your communication style to match your athletes’ processing preferences, ensuring clear and effective coaching that leads to improved performance.",
              "Build Trust": "Foster a trusting coach-athlete relationship by acting in ways that align with your athletes’ fundamental needs, ensuring they feel supported and respected.",
              "Provide Targeted Feedback": "Offer feedback that resonates with each athlete’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Resolve Team Conflicts": "Address conflicts within the team by understanding how each athlete manages stress and disagreements, ensuring resolutions that strengthen team cohesion.",
              "Promote Teamwork": "Encourage collaboration and unity among team members by recognizing and acting on the motivational drivers that keep your team connected.",
              "Develop Mental Toughness": "Help your athletes build resilience by understanding their emotional processing styles, ensuring they are equipped to handle pressure and adversity.",
              "Encourage Sportsmanship": "Instill values of fairness and respect in your athletes by understanding their motivational drivers, ensuring they uphold the principles of sportsmanship.",
              "Set Goals": "Work with your athletes to set achievable goals that align with their individual motivational drivers, ensuring they stay focused and motivated.",
              "Handle Performance Pressure": "Support your athletes in managing stress by understanding their pressure responses, ensuring they remain calm and focused during competitions."
          
      },
      "Teammate": {
         
              "Collaborate":" Work closely with teammates by understanding each other’s motivational drivers and decision-making styles, ensuring a productive and harmonious team environment.",
              "Communicate Effectively":" Tailor your communication style to match your teammates’ preferences, ensuring clear and effective exchanges that strengthen team cohesion.",
              "Build Trust":" Foster trust within the team by acting in ways that align with your teammates’ fundamental needs, ensuring they feel respected and valued.",
              "Motivate Each Other":" Encourage and inspire your teammates by understanding what drives them, ensuring your support resonates and pushes them to perform at their best.",
              "Resolve Conflict":" Address team conflicts by understanding how each teammate manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Foster Team Unity":" Contribute to a positive and cohesive team environment by recognizing and acting on the motivational drivers that keep your team connected.",
              "Share Leadership":" Take on leadership roles when needed by understanding your teammates’ decision-making and motivational drivers, ensuring you lead effectively and inclusively.",
              "Support During Losses":" Provide emotional support to teammates after a defeat by understanding their emotional needs, ensuring they feel comforted and understood.",
              "Manage Competition":" Handle internal team competition with respect and professionalism by understanding each teammate’s motivational drivers, ensuring a healthy and supportive environment.",
              "Encourage Fair Play":" Uphold the values of sportsmanship by understanding your teammates’ emotional needs, ensuring they respect and adhere to the principles of fair play."
  
      },
      "Referee/Umpire": {
  
              "Build Trust": "Strengthen your coaching relationship by acting in ways that align with your coach’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Goals Clearly": "Clearly articulate your objectives to help the coach tailor their guidance by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your aspirations.",
              "Provide Honest Feedback": "Share your thoughts on the coaching process by understanding your coach’s communication preferences, ensuring your feedback is received positively and leads to meaningful improvements.",
              "Be Open to New Perspectives": "Embrace the coach’s feedback and be willing to explore new ideas by understanding their decision-making and motivational drivers, ensuring you stay open-minded and receptive to growth.",
              "Demonstrate Commitment": "Show dedication to the coaching process by actively engaging in sessions and following through on recommendations, ensuring your coach feels valued and motivated to invest in your development.",
              "Share Challenges and Successes": "Keep your coach informed about your progress and any obstacles you face by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Respect the Coaching Process": "Follow through on the coach’s recommendations and exercises by understanding their motivational drivers, ensuring you stay aligned and committed to your growth.",
              "Seek Guidance on Leadership Growth": "Ask for specific advice on how to enhance your leadership skills by understanding your coach’s decision-making and motivational drivers, ensuring you receive tailored and effective guidance.",
              "Reflect on Insights": "Take time to reflect on the coach’s feedback and how it applies to your work by understanding your coach’s motivational drivers, ensuring you stay aligned and motivated to implement new strategies.",
              "Collaborate on Action Plans": "Work with your coach to develop and implement action plans that align with both your and your coach’s motivational drivers, ensuring you stay focused and motivated to achieve your goals."
          
      },
      "Sponsor": {
        
              "Build Trust": "Strengthen your business partnership by acting in ways that align with your partner’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Tailor your communication style to match your partner’s preferences, ensuring clear and effective exchanges that enhance your business collaboration.",
              "Collaborate on Strategic Decisions":"Make business decisions together by understanding each partner’s decision-making and motivational drivers, ensuring that both of you feel confident and aligned.",
              "Resolve Conflict": "Address business disputes with a deep understanding of how each partner manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Align on Vision and Goals": "Ensure that both partners share the same vision for the business by understanding each other’s motivational drivers and long-term goals, fostering a strong and aligned partnership.",
              "Share Leadership Roles": "Distribute leadership responsibilities in a way that leverages each partner’s strengths and stress points, ensuring a balanced and effective business operation.",
              "Handle Financial Management": "Manage the business’s finances collaboratively and transparently by understanding each partner’s needs for security and control, ensuring both partners feel confident and secure.",
              "Navigate Power Dynamics": "Balance power and influence within the partnership by understanding each partner’s motivational drivers, ensuring a fair and respectful business relationship.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your partner’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Plan for Long-Term Success":"Work together to develop strategies that ensure the business’s long-term viability by understanding each partner’s decision-making and motivational drivers, ensuring sustained success."
          
      },
      "Agent": {
              "Build Trust": "Strengthen your relationship with your manager by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Keep your manager informed about your progress and challenges by understanding their communication preferences, ensuring clear and effective exchanges.",
              "Provide Upward Feedback": "Offer constructive feedback to your manager by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to improved leadership.",
              "Present Ideas Clearly": "Share your ideas and suggestions in a clear and organized manner by understanding how your manager processes information, ensuring your input is valued and considered.",
              "Manage Expectations": "Align with your manager on priorities and deliverables by understanding their decision-making style, ensuring both of you feel confident and aligned.",
              "Resolve Conflict": "Address any disagreements with your manager calmly and professionally by understanding their conflict management style, ensuring a peaceful and productive resolution.",
              "Demonstrate Initiative": "Take proactive steps to contribute to your team’s success by understanding what drives your manager, ensuring your efforts are recognized and valued.",
              "Align with Team Goals": "Ensure your work supports the overall objectives of the team and organization by understanding your manager’s motivational drivers, ensuring both of you are working towards the same goals.",
              "Balance Workload Effectively": "Manage your tasks efficiently to meet deadlines and deliver quality work by understanding your manager’s expectations and processing style, ensuring a balanced and productive workload.",
              "Foster a Collaborative Environment": "Contribute to a positive and collaborative team culture by recognizing and acting on the motivational drivers that keep your team connected, ensuring a supportive and harmonious work environment."
          
      },
      "Other": {
         
              "Collaborate":" Work closely with teammates by understanding each other’s motivational drivers and decision-making styles, ensuring a productive and harmonious team environment.",
              "Communicate Effectively":" Tailor your communication style to match your teammates’ preferences, ensuring clear and effective exchanges that strengthen team cohesion.",
              "Build Trust":" Foster trust within the team by acting in ways that align with your teammates’ fundamental needs, ensuring they feel respected and valued.",
              "Motivate Each Other":" Encourage and inspire your teammates by understanding what drives them, ensuring your support resonates and pushes them to perform at their best.",
              "Resolve Conflict":" Address team conflicts by understanding how each teammate manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Foster Team Unity":" Contribute to a positive and cohesive team environment by recognizing and acting on the motivational drivers that keep your team connected.",
              "Share Leadership":" Take on leadership roles when needed by understanding your teammates’ decision-making and motivational drivers, ensuring you lead effectively and inclusively.",
              "Support During Losses":" Provide emotional support to teammates after a defeat by understanding their emotional needs, ensuring they feel comforted and understood.",
              "Manage Competition":" Handle internal team competition with respect and professionalism by understanding each teammate’s motivational drivers, ensuring a healthy and supportive environment.",
              "Encourage Fair Play":" Uphold the values of sportsmanship by understanding your teammates’ emotional needs, ensuring they respect and adhere to the principles of fair play."
  
      },
  
 
      "Mentor":  {
              "Build Trust": "Strengthen your mentoring relationship by acting in ways that align with your mentor’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Openly": "Share your goals, challenges, and progress with your mentor by understanding their communication preferences, ensuring clear and effective exchanges.",
              "Ask for Feedback": "Actively seek constructive feedback from your mentor by understanding their decision-making and motivational drivers, ensuring it is received positively and leads to meaningful improvements.",
              "Show Appreciation": "Express gratitude for your mentor’s time and guidance in a way that resonates with their emotional needs, ensuring they feel valued and respected.",
              "Share Your Goals": "Clearly communicate your objectives by understanding your mentor’s decision-making style, ensuring they feel aligned and supportive of your aspirations.",
              "Seek Guidance": "Be proactive in asking for advice and support by understanding what drives your mentor, ensuring they feel valued and motivated to invest in your development.",
              "Demonstrate Willingness to Learn": "Show enthusiasm and a commitment to learning from your mentor by understanding their motivational drivers, ensuring they feel valued and respected.",
              "Respect Mentor’s Time": "Be mindful of your mentor’s time and schedule meetings accordingly by understanding their need for structure or flexibility, ensuring a respectful and supportive relationship.",
              "Provide Updates on Progress": "Keep your mentor informed about your achievements and areas for improvement by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your progress.",
              "Be Open to Challenges": "Embrace opportunities for growth, even when they push you out of your comfort zone, by understanding your mentor’s expectations and motivational drivers, ensuring a positive and productive mentoring experience."
          
      },
      "Executive Coach": {
              "Build Trust": "Strengthen your coaching relationship by acting in ways that align with your coach’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Goals Clearly": "Clearly articulate your objectives to help the coach tailor their guidance by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your aspirations.",
              "Provide Honest Feedback": "Share your thoughts on the coaching process by understanding your coach’s communication preferences, ensuring your feedback is received positively and leads to meaningful improvements.",
              "Be Open to New Perspectives": "Embrace the coach’s feedback and be willing to explore new ideas by understanding their decision-making and motivational drivers, ensuring you stay open-minded and receptive to growth.",
              "Demonstrate Commitment": "Show dedication to the coaching process by actively engaging in sessions and following through on recommendations, ensuring your coach feels valued and motivated to invest in your development.",
              "Share Challenges and Successes": "Keep your coach informed about your progress and any obstacles you face by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Respect the Coaching Process": "Follow through on the coach’s recommendations and exercises by understanding their motivational drivers, ensuring you stay aligned and committed to your growth.",
              "Seek Guidance on Leadership Growth": "Ask for specific advice on how to enhance your leadership skills by understanding your coach’s decision-making and motivational drivers, ensuring you receive tailored and effective guidance.",
              "Reflect on Insights": "Take time to reflect on the coach’s feedback and how it applies to your work by understanding your coach’s motivational drivers, ensuring you stay aligned and motivated to implement new strategies.",
              "Collaborate on Action Plans": "Work with your coach to develop and implement action plans that align with both your and your coach’s motivational drivers, ensuring you stay focused and motivated to achieve your goals."
          
      },
      "Doctor":  {
              "Build Trust": "Strengthen your relationship with your doctor by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Symptoms and Concerns Clearly": "Provide detailed and accurate information about your health by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and confident in your care.",
              "Follow Medical Advice": "Adhere to the treatment plan and follow your doctor’s recommendations by understanding their decision-making and motivational drivers, ensuring they feel confident and secure in your commitment to your healthcare.",
              "Ask Questions and Clarifications": "Seek clarity on any aspects of your diagnosis or treatment that you don’t understand by understanding your doctor’s communication preferences, ensuring they feel valued and respected in your healthcare journey.",
              "Share Complete Medical History": "Ensure your doctor has all the information needed to provide the best care by understanding their processing preferences, ensuring they feel informed and confident in your care.",
              "Provide Feedback on Treatment": "Offer insights into how the treatment is working for you by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and aligned with your healthcare goals.",
              "Respect Doctor’s Expertise": "Trust in your doctor’s knowledge and experience in managing your health by understanding their fundamental needs and motivational drivers, ensuring they feel respected and valued in your healthcare journey.",
              "Stay Engaged in Health Decisions": "Be an active participant in your healthcare decisions by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and confident in your commitment to your health.",
              "Adhere to Treatment Plans": "Follow through with prescribed treatments and report any issues promptly by understanding your doctor’s decision-making and motivational drivers, ensuring they feel confident and secure in your healthcare journey.",
              "Be Honest About Challenges and Symptoms": "Share any difficulties you face with your treatment or symptoms by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and aligned with your healthcare goals."
          
      },
       "Student": {
  
              "Build Trust": "Establish a trusting relationship with your students by understanding their fundamental needs and learning styles, ensuring they feel supported and respected in the classroom.",
              "Communicate Effectively": "Tailor your communication style to match your students' processing preferences, ensuring clear and engaging instruction that enhances their learning.",
              "Provide Constructive Feedback": "Offer feedback that resonates with each student’s emotional and motivational needs, ensuring it is received positively and leads to meaningful academic and personal growth.",
              "Motivate": "Inspire your students to achieve their best by understanding what drives each one, ensuring your encouragement resonates and pushes them to excel in their studies.",
              "Manage Classroom Dynamics": "Create a positive and inclusive classroom environment by understanding the diverse needs and personalities of your students, ensuring a supportive learning atmosphere.",
              "Resolve Conflicts": "Address conflicts among students with empathy and understanding, fostering a peaceful and cooperative classroom environment.",
              "Encourage Active Participation": "Engage students in the learning process by recognizing their strengths and tailoring your teaching methods to their needs, ensuring they feel motivated to participate.",
              "Foster Critical Thinking": "Challenge students to think critically and creatively by understanding their processing styles, ensuring they feel empowered to explore new ideas.",
              "Support Personal Growth": "Provide guidance that extends beyond academics, helping students develop life skills and emotional intelligence by understanding their unique needs and challenges.",
              "Celebrate Achievements": "Recognize and celebrate student successes in a way that resonates with their motivational drivers, ensuring they feel valued and encouraged to continue striving for excellence."
          
      },
       "Teacher": {
  
              "Build Trust": "Establish a respectful and supportive relationship with your teacher by being open and honest, ensuring they feel valued and trusted as your educator.",
              "Communicate Effectively": "Share your learning needs and challenges with your teacher by understanding their communication style, ensuring clear and productive exchanges that support your academic success.",
              "Seek Feedback": "Actively seek constructive feedback from your teacher by understanding their expectations and motivational drivers, ensuring it leads to meaningful improvements in your studies.",
              "Demonstrate Initiative": "Show enthusiasm and a commitment to your learning by understanding your teacher’s expectations, ensuring they feel motivated to invest in your academic growth.",
              "Engage in Classroom Activities": "Participate actively in class by understanding how your teacher structures lessons, ensuring you contribute positively to the learning environment.",
              "Resolve Conflicts": "Address any disagreements with your teacher calmly and respectfully by understanding their conflict management style, ensuring a peaceful and productive resolution.",
              "Show Appreciation": "Express gratitude for your teacher’s efforts in a way that resonates with their emotional needs, ensuring they feel valued and respected as your educator.",
              "Align on Academic Goals": "Work with your teacher to set and achieve academic goals by understanding their expectations and motivational drivers, ensuring you stay focused and motivated.",
              "Collaborate on Learning Plans": "Develop a personalized learning plan with your teacher by understanding their teaching style, ensuring your educational needs are met effectively.",
              "Respect Classroom Dynamics": "Contribute positively to the classroom environment by understanding the diverse needs and personalities of your peers, ensuring a supportive and inclusive learning experience."
          
      },
      "Patient": {
  
              "Build Trust": "Establish a supportive doctor-patient relationship by acting in ways that align with the patient’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Effectively": "Provide clear explanations about diagnoses, treatment options, and next steps by understanding the patient’s decision-making and motivational drivers, ensuring they feel confident and informed.",
              "Provide Clear Information": "Ensure the patient fully understands their condition and treatment plan by understanding their processing preferences, ensuring they feel informed and secure in their healthcare decisions.",
              "Show Empathy and Compassion": "Offer emotional support and understanding to patients in distress by understanding their emotional needs, ensuring they feel comforted and supported in their healthcare journey.",
              "Resolve Concerns": "Address any worries or doubts the patient may have about their health or treatment by understanding their decision-making and motivational drivers, ensuring they feel confident and secure in your care.",
              "Respect Patient Autonomy": "Honor the patient’s right to make informed decisions about their care by understanding their fundamental needs for control and security, ensuring they feel confident and respected in your care.",
              "Encourage Treatment Adherence": "Motivate the patient to follow their treatment plan consistently by understanding their decision-making and motivational drivers, ensuring they feel confident and committed to their healthcare.",
              "Manage Expectations": "Set realistic expectations regarding outcomes and recovery times by understanding the patient’s decision-making and motivational drivers, ensuring they feel confident and secure in their healthcare journey.",
              "Navigate Difficult Conversations": "Handle sensitive topics, such as prognosis, with care and compassion by understanding the patient’s emotional needs, ensuring they feel comforted and supported in their healthcare journey.",
              "Support Mental and Emotional Well-being": "Recognize and address the patient’s mental and emotional health needs by understanding their fundamental needs and motivational drivers, ensuring they feel supported and cared for in their healthcare journey."
          
      },
      "Therapist/Counselor":  {
              "Build Trust": "Strengthen your relationship with your doctor by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Symptoms and Concerns Clearly": "Provide detailed and accurate information about your health by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and confident in your care.",
              "Follow Medical Advice": "Adhere to the treatment plan and follow your doctor’s recommendations by understanding their decision-making and motivational drivers, ensuring they feel confident and secure in your commitment to your healthcare.",
              "Ask Questions and Clarifications": "Seek clarity on any aspects of your diagnosis or treatment that you don’t understand by understanding your doctor’s communication preferences, ensuring they feel valued and respected in your healthcare journey.",
              "Share Complete Medical History": "Ensure your doctor has all the information needed to provide the best care by understanding their processing preferences, ensuring they feel informed and confident in your care.",
              "Provide Feedback on Treatment": "Offer insights into how the treatment is working for you by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and aligned with your healthcare goals.",
              "Respect Doctor’s Expertise": "Trust in your doctor’s knowledge and experience in managing your health by understanding their fundamental needs and motivational drivers, ensuring they feel respected and valued in your healthcare journey.",
              "Stay Engaged in Health Decisions": "Be an active participant in your healthcare decisions by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and confident in your commitment to your health.",
              "Adhere to Treatment Plans": "Follow through with prescribed treatments and report any issues promptly by understanding your doctor’s decision-making and motivational drivers, ensuring they feel confident and secure in your healthcare journey.",
              "Be Honest About Challenges and Symptoms": "Share any difficulties you face with your treatment or symptoms by understanding your doctor’s decision-making and motivational drivers, ensuring they feel informed and aligned with your healthcare goals."
          
      },
      "Advisor": {
         
              "Build Trust": "Strengthen your advisor-client relationship by acting in ways that align with the advisor’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Needs and Goals": "Clearly articulate your objectives to help the advisor provide relevant advice by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your aspirations.",
              "Provide Clear Information": "Share all relevant details with your advisor by understanding their processing preferences, ensuring they feel informed and confident in your guidance.",
              "Be Open to Feedback": "Listen to and consider the advisor’s feedback by understanding their decision-making and motivational drivers, ensuring they feel valued and respected in the advisor-client relationship.",
              "Respect Advisor’s Expertise": "Recognize and value the advisor’s knowledge and experience by understanding their fundamental needs and motivational drivers, ensuring they feel respected and valued in the relationship.",
              "Seek Strategic Guidance": "Ask for specific advice on how to achieve your goals by understanding your advisor’s decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Collaborate on Solutions": "Work with your advisor to develop actionable plans by understanding their decision-making and motivational drivers, ensuring they feel confident and supportive of your journey.",
              "Share Progress and Challenges": "Keep your advisor updated on your progress and any obstacles you encounter by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Ask Questions": "Clarify any uncertainties by asking your advisor thoughtful questions by understanding their communication preferences, ensuring they feel valued and respected in the relationship.",
              "Value the Advisor-Client Relationship": "Invest in building a strong, trusting relationship with your advisor by understanding their fundamental needs and processing styles, ensuring they feel respected and valued"
      },
      "Legal Counsel": {
  
              "Build Trust": "Strengthen your relationship with your attorney by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Needs and Concerns": "Clearly express your legal needs and concerns by understanding your attorney’s decision-making and motivational drivers, ensuring they feel informed and aligned with your goals.",
              "Provide Complete and Accurate Information": "Share all relevant facts with your attorney by understanding their processing preferences, ensuring they feel informed and confident in your case.",
              "Follow Legal Advice": "Adhere to your attorney’s guidance by understanding their decision-making and motivational drivers, ensuring they feel confident and secure in your commitment to the case.",
              "Manage Expectations": "Understand and align your expectations with the legal process and possible outcomes by understanding your attorney’s decision-making and motivational drivers, ensuring they feel informed and confident in your case.",
              "Be Open to Legal Strategies": "Consider your attorney’s strategies by understanding their decision-making and motivational drivers, ensuring they feel valued and respected in the attorney-client relationship.",
              "Respect Attorney’s Expertise": "Recognize and value the attorney’s legal knowledge and experience by understanding their fundamental needs and motivational drivers, ensuring they feel respected and valued in the relationship.",
              "Stay Engaged in Legal Process": "Remain involved in your legal matters by staying informed and proactive, understanding your attorney’s decision-making and motivational drivers, ensuring they feel confident and secure in your commitment to the case.",
              "Provide Feedback on Representation": "Offer feedback to your attorney by understanding their decision-making and motivational drivers, ensuring they feel valued and respected in the attorney-client relationship.",
              "Maintain Regular Communication": "Keep in touch with your attorney by understanding their communication preferences, ensuring they feel informed and aligned with your legal goals."
          
      },
  
      "Student": {
  
              "Build Trust": "Establish a trusting relationship with your students by understanding their fundamental needs and learning styles, ensuring they feel supported and respected in the classroom.",
              "Communicate Effectively": "Tailor your communication style to match your students' processing preferences, ensuring clear and engaging instruction that enhances their learning.",
              "Provide Constructive Feedback": "Offer feedback that resonates with each student’s emotional and motivational needs, ensuring it is received positively and leads to meaningful academic and personal growth.",
              "Motivate": "Inspire your students to achieve their best by understanding what drives each one, ensuring your encouragement resonates and pushes them to excel in their studies.",
              "Manage Classroom Dynamics": "Create a positive and inclusive classroom environment by understanding the diverse needs and personalities of your students, ensuring a supportive learning atmosphere.",
              "Resolve Conflicts": "Address conflicts among students with empathy and understanding, fostering a peaceful and cooperative classroom environment.",
              "Encourage Active Participation": "Engage students in the learning process by recognizing their strengths and tailoring your teaching methods to their needs, ensuring they feel motivated to participate.",
              "Foster Critical Thinking": "Challenge students to think critically and creatively by understanding their processing styles, ensuring they feel empowered to explore new ideas.",
              "Support Personal Growth": "Provide guidance that extends beyond academics, helping students develop life skills and emotional intelligence by understanding their unique needs and challenges.",
              "Celebrate Achievements": "Recognize and celebrate student successes in a way that resonates with their motivational drivers, ensuring they feel valued and encouraged to continue striving for excellence."
          
      },
      "Caregiver":{
              "Build Trust": "Strengthen your relationship with your parents by acting in ways that align with their fundamental needs and processing styles, ensuring they feel respected and understood.",
              "Communicate Effectively": "Communicate with your parents in a manner that resonates with their preferred way of processing information, ensuring clarity and reducing misunderstandings.",
              "Show Appreciation": "Express appreciation in a way that truly resonates with your parents’ emotional needs, ensuring they feel valued and acknowledged.",
              "Resolve Conflict": "Navigate conflicts with your parents by considering how they handle stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Empathy": "Approach disagreements with empathy by understanding your parents’ decision-making styles, ensuring that discussions are respectful and productive.",
              "Seek and Give Support": "Offer and ask for support in ways that align with your parents’ motivational drivers, ensuring that your interactions are meaningful and supportive.",
              "Respect Parental Boundaries": "Understand and honor your parents’ boundaries by recognizing their need for control or security, fostering a healthy and respectful relationship.",
              "Navigate Life Changes": "Help your parents through life transitions by understanding how they process stress and change, ensuring they feel supported and valued.",
              "Apologize": "Offer apologies that address your parents’ specific needs for validation, ensuring that reconciliation is sincere and effective.",
              "Strengthen Family Bonds": "Invest in your family relationships by recognizing and acting on the emotional and psychological needs that keep your family connected."
          },
      "Accountant": {
              "Build Trust": "Establish a confidential and supportive attorney-client relationship by acting in ways that align with the client’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Legal Options Clearly": "Provide clear explanations of legal options and implications by understanding the client’s decision-making and motivational drivers, ensuring they feel confident and informed.",
              "Provide Expert Legal Advice": "Offer guidance that helps the client navigate complex legal issues by understanding their decision-making and motivational drivers, ensuring they feel confident and secure in their decisions.",
              "Navigate Complex Legal Issues": "Assist the client in understanding and addressing intricate legal matters by understanding their processing preferences, ensuring they feel informed and confident in your guidance.",
              "Manage Client Expectations": "Set realistic expectations regarding outcomes and timelines by understanding the client’s decision-making and motivational drivers, ensuring they feel confident and secure in the legal process.",
              "Maintain Confidentiality": "Protect the client’s privacy by understanding their fundamental needs for security and control, ensuring they feel confident and secure in your representation.",
              "Advocate for Client’s Best Interests": "Represent the client’s interests zealously and ethically by understanding their fundamental needs and motivational drivers, ensuring they feel confident and secure in your advocacy.",
              "Resolve Legal Disputes": "Work towards resolving legal conflicts in the client’s favor by understanding their conflict management style, ensuring they feel confident and secure in your representation.",
              "Ensure Ethical Compliance": "Guide the client in making decisions that are legally and ethically sound by understanding their fundamental needs and motivational drivers, ensuring they feel confident and secure in their decisions.",
              "Offer Continuous Legal Support": "Be available to provide ongoing legal advice and representation by understanding the client’s fundamental needs and processing styles, ensuring they feel confident and secure in your guidance."
          
      },
      "Priest/Pastor":{
              "Build Trust": "Strengthen your coaching relationship by acting in ways that align with your coach’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Goals Clearly": "Clearly articulate your objectives to help the coach tailor their guidance by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your aspirations.",
              "Provide Honest Feedback": "Share your thoughts on the coaching process by understanding your coach’s communication preferences, ensuring your feedback is received positively and leads to meaningful improvements.",
              "Be Open to New Perspectives": "Embrace the coach’s feedback and be willing to explore new ideas by understanding their decision-making and motivational drivers, ensuring you stay open-minded and receptive to growth.",
              "Demonstrate Commitment": "Show dedication to the coaching process by actively engaging in sessions and following through on recommendations, ensuring your coach feels valued and motivated to invest in your development.",
              "Share Challenges and Successes": "Keep your coach informed about your progress and any obstacles you face by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Respect the Coaching Process": "Follow through on the coach’s recommendations and exercises by understanding their motivational drivers, ensuring you stay aligned and committed to your growth.",
              "Seek Guidance on Leadership Growth": "Ask for specific advice on how to enhance your leadership skills by understanding your coach’s decision-making and motivational drivers, ensuring you receive tailored and effective guidance.",
              "Reflect on Insights": "Take time to reflect on the coach’s feedback and how it applies to your work by understanding your coach’s motivational drivers, ensuring you stay aligned and motivated to implement new strategies.",
              "Collaborate on Action Plans": "Work with your coach to develop and implement action plans that align with both your and your coach’s motivational drivers, ensuring you stay focused and motivated to achieve your goals."
          
      },
      "Rabbi":{
              "Build Trust": "Strengthen your coaching relationship by acting in ways that align with your coach’s fundamental needs and processing styles, ensuring they feel respected and valued.",
              "Communicate Goals Clearly": "Clearly articulate your objectives to help the coach tailor their guidance by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your aspirations.",
              "Provide Honest Feedback": "Share your thoughts on the coaching process by understanding your coach’s communication preferences, ensuring your feedback is received positively and leads to meaningful improvements.",
              "Be Open to New Perspectives": "Embrace the coach’s feedback and be willing to explore new ideas by understanding their decision-making and motivational drivers, ensuring you stay open-minded and receptive to growth.",
              "Demonstrate Commitment": "Show dedication to the coaching process by actively engaging in sessions and following through on recommendations, ensuring your coach feels valued and motivated to invest in your development.",
              "Share Challenges and Successes": "Keep your coach informed about your progress and any obstacles you face by understanding their decision-making and motivational drivers, ensuring they feel aligned and supportive of your journey.",
              "Respect the Coaching Process": "Follow through on the coach’s recommendations and exercises by understanding their motivational drivers, ensuring you stay aligned and committed to your growth.",
              "Seek Guidance on Leadership Growth": "Ask for specific advice on how to enhance your leadership skills by understanding your coach’s decision-making and motivational drivers, ensuring you receive tailored and effective guidance.",
              "Reflect on Insights": "Take time to reflect on the coach’s feedback and how it applies to your work by understanding your coach’s motivational drivers, ensuring you stay aligned and motivated to implement new strategies.",
              "Collaborate on Action Plans": "Work with your coach to develop and implement action plans that align with both your and your coach’s motivational drivers, ensuring you stay focused and motivated to achieve your goals."
          
      },
      "Neighbor":{
              "Build Trust": "Strengthen your friendship by acting in ways that align with your friend’s fundamental needs and processing styles, ensuring they feel valued and supported.",
              "Communicate Effectively": "Tailor your communication style to match your friend’s preferences, ensuring clear and meaningful interactions that strengthen your bond.",
              "Be There in Tough Times": "Offer support during difficult times by understanding your friend’s motivational drivers and emotional needs, ensuring they feel genuinely cared for.",
              "Disagree with Empathy": "Navigate disagreements with empathy by understanding how your friend processes emotions and makes decisions, fostering a deeper and more resilient friendship.",
              "Resolve Conflict": "Address conflicts with your friend in a way that respects their unique conflict management style, ensuring resolutions that strengthen your relationship.",
              "Support Growth and Change": "Encourage and support your friend’s personal growth by recognizing their motivational drivers, ensuring they feel understood and valued.",
              "Navigate Jealousy and Comparison": "Manage feelings of envy constructively by understanding your friend’s emotional needs, ensuring a healthy and supportive friendship.",
              "Celebrate Successes Together": "Acknowledge and celebrate your friend’s achievements in a way that resonates with their motivational drivers, strengthening your bond.",
              "Apologize": "Offer apologies that address your friend’s specific needs for validation and emotional healing, ensuring a sincere and effective reconciliation.",
              "Maintain Mutual Interests": "Engage in activities and conversations that reflect both of your interests, ensuring a strong and lasting friendship."
          
      },
      "Other":{
              "Communicate Effectively": "Tailor your communication style to align with your colleague’s processing preferences, ensuring clear and effective exchanges that enhance workplace collaboration.",
              "Collaborate": "Work together efficiently by understanding each other’s motivational drivers and decision-making styles, ensuring a productive and harmonious work environment.",
              "Build Trust": "Foster trust in the workplace by acting in ways that align with your colleague’s fundamental needs, ensuring they feel respected and valued.",
              "Provide Constructive Feedback": "Offer feedback that resonates with your colleague’s emotional and motivational needs, ensuring it is received positively and leads to meaningful improvements.",
              "Manage Professional Boundaries": "Maintain appropriate boundaries by recognizing each other’s needs for autonomy and security, fostering a respectful and supportive work relationship.",
              "Resolve Conflict": "Address workplace conflicts with a deep understanding of how each colleague manages stress and disagreements, leading to more peaceful and constructive resolutions.",
              "Disagree with Respect": "Navigate professional disagreements with respect by understanding each colleague’s decision-making and emotional processing styles, ensuring discussions remain productive.",
              "Foster Team Spirit": "Contribute to a positive and collaborative team environment by recognizing and acting on the motivational drivers that keep your team connected.",
              "Share Credit and Success": "Recognize and celebrate your colleagues’ contributions in a way that resonates with their motivational needs, ensuring a supportive and appreciative workplace culture.",
              "Handle Workplace Dynamics": "Navigate office politics and dynamics with insight into how each colleague processes stress and conflict, ensuring a balanced and effective work environment."
          
      }
     
  }
  
  
  
  
  

        

    const containerStyle = {
        padding: '40px',
        backgroundColor: '#000000',
        minHeight: '100vh',
        color: 'white',
        fontFamily: 'Arial, sans-serif'
    };

    const titleStyle = {
        fontSize: '2.5rem',
        textAlign: 'center',
        marginBottom: '20px'
    };

    const descriptionStyle = {
        fontSize: '1rem',
        lineHeight: '1.6',
        marginBottom: '40px',
        color: '#cccccc',
        textAlign: 'center',
        maxWidth: '800px',
        margin: '0 auto 40px auto'
    };

    const sectionTitleStyle = {
        fontSize: '1.5rem',
        marginBottom: '20px',
        marginTop: '30px'
    };

    const nameContainerStyle = {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
        marginBottom: '30px'
    };

    const nameButtonStyle = (isSelected) => ({
        padding: '10px 30px',
        backgroundColor: 'transparent',
        border: '1px solid #5bacb9',
        borderRadius: '5px',
        color: 'white',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        backgroundColor: isSelected ? '#5bacb9' : 'transparent'
    });

    const topicContainerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '15px',
        marginBottom: '40px'
    };

    const topicButtonStyle = (isSelected) => ({
        padding: '12px 25px',
        backgroundColor: isSelected ? '#5bacb9' : 'transparent',
        border: '1px solid #5bacb9',
        borderRadius: '5px',
        color: 'white',
        cursor: 'pointer',
        transition: 'all 0.3s ease'
    });

    const proceedButtonStyle = {
        padding: '13px 35px',
        backgroundColor: (selectedName && selectedTopic) ? '#5bacb9' : '#549fac',
        border: 'none',
        borderRadius: '5px',
        color: 'white',
        cursor: ( selectedTopic) ? 'pointer' : 'not-allowed',
        fontSize: '1.1rem',
        display: 'block',
        margin: '40px auto'
    };

    const nextButtonStyle = {
        padding: '10px 35px',
        backgroundColor: (selectedTopic) ? '#5bacb9' : '#549fac',
        border: 'none',
        borderRadius: '5px',
        color: 'white',
        cursor: ( selectedTopic) ? 'pointer' : 'not-allowed',
        fontSize: '1.1rem',
        display: 'block',
        margin: '40px auto'
    };

    
    const handleNext=()=>{
        setUserSelected(true);

    }
    const handleBackButtonClick = () => {
        
       
          navigate('/relationship-advisor');
        
        
       
      };

    return (
        <div style={containerStyle}>
             <div style={{ position: 'absolute', top: '23px', left: '40px'}}>
        <button
          size={30} 
          color="#6cb4c2" 
          onClick={handleBackButtonClick} 
          style={{ cursor: 'pointer' ,width:'50px',height:'27px',backgroundColor:'black',color:'#69b3c1',border:'1.2px solid #69b3c1',borderRadius:'5px'}}
        >Back</button>
      </div>
            <h1 style={titleStyle}>{relationship}</h1>
            <p style={descriptionStyle}>
                {relationshipTopics[relationship]?.description}
            </p>

       

           
{/* {!userSelected &&(
            <button 
                style={nextButtonStyle}
                onClick={handleNext}
                disabled={ !selectedTopic}
            >
                Next
            </button>
)
} */}



            {/* <h2 style={sectionTitleStyle}>Select a name</h2>
            <div style={nameContainerStyle}>
            {Object.keys(relationshipData).map((relation) => (
        <button
            key={relation}
            style={nameButtonStyle(selectedName === relation)}
            onClick={() => setSelectedName(relation)}
        >
            {relation}
        </button>
    ))}
            </div> */}
            {Object.keys(relationshipData).length !== 0 ? (
    <>
        <h2 style={sectionTitleStyle}>Select a name</h2>
        <div style={nameContainerStyle}>
            {Object.keys(relationshipData).map((relation) => (
                <button
                    key={relation}
                    style={nameButtonStyle(selectedName === relation)}
                    onClick={() => setSelectedName(relation)}
                >
                    {relation}
                </button>
            ))}
        </div>
    </>
) : (
    <p>No data available</p>
)}






            {selectedName && (
  <>
    <h2 style={sectionTitleStyle}>Choose a Topic</h2>
    <div style={topicContainerStyle}>
      {Object.keys(relationshipTopics[selectedName] || {}).map((topic) => (
        <button
          key={topic}
          style={topicButtonStyle(selectedTopic === topic)}
          onClick={() => setSelectedTopic(topic)}
          title={relationshipTopics[selectedName][topic]} // Tooltip with description
        >
          {topic.replace(/_/g, " ")}
        </button>
      ))}
    </div>
  </>
)}



{selectedName &&(

<div style={toggleContainerStyle}>
        {/* User Under Pressure Toggle */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={toggleLabelStyle}>You are under pressure</span>
          <div 
            style={isUserUnderPressure ? toggleButtonActiveStyle : toggleButtonStyle} 
            onClick={handleUserPressureToggle}
          >
            <div style={toggleSwitchStyle(isUserUnderPressure)}></div>
          </div>
        </div>

        {/* Colleague Under Pressure Toggle */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={toggleLabelStyle}>{relationshipData[selectedName]} under pressure</span>
          <div 
            style={isColleagueUnderPressure ? toggleButtonActiveStyle : toggleButtonStyle} 
            onClick={handleColleaguePressureToggle}
          >
            <div style={toggleSwitchStyle(isColleagueUnderPressure)}></div>
          </div>
        </div>
      </div>
)}

{selectedName && (
        <button 
          style={proceedButtonStyle}
          onClick={handleProceedClick}
          disabled={!selectedName || !selectedTopic}
        >
          Proceed
        </button>
      )}

  {/* New Toggle Buttons Section */}
 
  <SituationPopup 
        isOpen={showSituationPopup}
        onClose={() => setShowSituationPopup(false)}
        onProceed={handleProceed}
        name= {user}
        partner_name= {relationshipData[selectedName]}
        relation={selectedName}
        relationship={relationship}
        topic={ selectedTopic}
        u1_Pstatus={isUserUnderPressure}
        u2_Pstatus={isColleagueUnderPressure}
        
        belief_system={ selectedBeliefSystem||{}}
      />

      {/* Rest of the existing component */}
      <ResponsePopup 
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        streamingContent={streamingContent}
      />
    </div>
    );
};

export default RelationshipTopic;