import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import arislogo from './assests/aris-logo-cut.png';
import logout from './assests/logout.png'
import { position } from '@chakra-ui/react';
import { useUser } from './UserContext.js';
import { useState } from 'react';
import axios from 'axios';
import BeliefSystem from './BeliefSystem.js';

const Homepage = ({ username, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { selectedBeliefSystem ,setSelectedBeliefSystem} = useUser();

  const { user } = useUser();


  const navigateToCategory = (category) => {
    navigate('/daily-topics', { 
      state: { category: category } 
    });
  };
  const fetchInsights = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Clean the URL
      const apiUrl = 'https://lab-api.aris.ai/ARIS/relationship'.trim();
    
      // Prepare query parameters
      const params = new URLSearchParams({
        name: user.trim(), // Ensure user value is clean
        type: 'List'
      });
    
      // API call using fetch
      const response = await fetch(`${apiUrl}?${params.toString()}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    
      const data = await response.json();
    
      // Navigate to insights page and pass data
      navigate('/insights', { 
        state: { 
          insightsData: data 
        } 
      });
    } catch (err) {
      // Handle any errors
      setError(err.message);
      console.error('Error fetching insights:', err);
      
      // Optionally, you might want to show an error notification
      // or handle the error in a user-friendly way
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      // Check if we're currently on the homepage
      if (location.pathname === '/homepage') {
        // Prevent the default back action
        event.preventDefault();
        // Navigate to the root page
        navigate('/', { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location]);

  const buttonVariants = {
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    overflow: 'hidden',
    padding: '20px',
  };
  
  const logoutButtonStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '35px',
    height: '35px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  };
  
  const logoStyle = {
    width: '130px',
    height: '60px',
    marginBottom: '2rem',
  };
  
  const titleStyle = {
    fontSize: 'clamp(1rem, 5vw, 2rem)',
    fontWeight: 'bold',
    marginBottom: '3rem',
    color: '#69B3C1',
    textAlign: 'center',
  };
  
  const buttonContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    width: '100%',
    maxWidth: '900px', // Increased max-width to allow horizontal layout
  };
  
  const buttonBaseStyle = {
    flex: '0 1 calc(33.333% - 1rem)', // Allows 3 buttons per row
    padding: '1rem',
    fontSize: 'clamp(0.8rem, 3vw, 1rem)', // Slightly smaller font for horizontal layout
    fontWeight: '600',
    borderRadius: '9999px',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    minWidth: '200px', // Ensures buttons have a minimum width
  };
  
  const speechButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: '#69B3C1',
    color: 'black',
  };
  
  const textButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: 'black',
    color: '#69B3C1',
    border: '2px solid #69B3C1',
  };
  return (
    <div style={containerStyle}>
    <img src={logout} alt="Logout" style={logoutButtonStyle} onClick={onLogout} />
    <img src={arislogo} alt="Aris logo" style={logoStyle} />
    <motion.h1
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8 }}
      style={titleStyle}
    >
      How can I help you live a fulfilled and joyful life?
    </motion.h1>
    <div style={buttonContainerStyle}>
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={speechButtonStyle}
        onClick={() =>
          {
            setSelectedBeliefSystem({});
          navigate('/speech-assistant')}}
      >
        Live Coaching Session
      </motion.button>
     
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={textButtonStyle}
        onClick={() =>
          {
            setSelectedBeliefSystem({});
          
          navigate('/text-assistant')}}
      >
        Chat Coaching Session
      </motion.button>
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={speechButtonStyle}
        onClick={() => 
          {
            setSelectedBeliefSystem({});
          
          navigate('/relationship-advisor')}}
      >
        Relationship Advice
      </motion.button>
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={textButtonStyle}
        onClick={() => {
          setSelectedBeliefSystem({});
          fetchInsights();
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Relationship Insights'}
      </motion.button>
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={speechButtonStyle}
        onClick={() => 
          {
            setSelectedBeliefSystem({});
          
          navigateToCategory('Mindset')}}
        disabled={isLoading}
      >
        {'Mindset'}
      </motion.button>

      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={textButtonStyle}
        onClick={() => {
          setSelectedBeliefSystem({});
          
          navigateToCategory('Career')}}
        disabled={isLoading}
      >
        {'Career'}
      </motion.button>
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        style={textButtonStyle}
        onClick={() => 
          {
            setSelectedBeliefSystem({});
          navigateToCategory('Well being')}}
        disabled={isLoading}
      >
        {'Well being'}
      </motion.button>
    </div>
  </div>
  );
};

export default Homepage;