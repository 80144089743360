import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import BeliefSystem from './BeliefSystem.js';

import { useUser } from './UserContext.js';
import ReactMarkdown from 'react-markdown';

import { useNavigate } from 'react-router-dom';
import { 
    Heading, 
    Text, 
    UnorderedList, 
    ListItem, 
    Box 
  } from '@chakra-ui/react';



const ResponsePopup = ({ isOpen, onClose, streamingContent }) => {
    if (!isOpen) return null;
    const textColor = "black";
    const renderers = {
        h1: ({ children }) => (
            <Heading as="h1" size="xl" color={textColor} fontWeight="bold" mb={2}>
                {children}
            </Heading>
        ),
        h2: ({ children }) => (
            <Heading as="h2" size="lg" color={textColor} fontWeight="bold" mb={2}>
                {children}
            </Heading>
        ),
        h3: ({ children }) => (
            <Heading as="h3" size="md" color={textColor} fontWeight="bold" mb={2}>
                {children}
            </Heading>
        ),
        p: ({ children }) => (
            <Text fontSize="md" color={textColor} mb={2}>
                {children}
            </Text>
        ),
        strong: ({ children }) => (
            <Text as="strong" fontWeight="bold" color={textColor} mb={2}>
                {children}
            </Text>
        ),
        ul: ({ children }) => (
            <UnorderedList pl={5} mb={2} styleType="disc" color={textColor}>
                {children}
            </UnorderedList>
        ),
        li: ({ children }) => (
            <ListItem fontSize="md" color={textColor} mb={1}>
                {children}
            </ListItem>
        ),
        br: () => <Box height="1rem" /> // Ensures new line rendering
    };
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          width: '80%',
          maxWidth: '700px',
          maxHeight: '80vh',
          position: 'relative',
          overflow: 'auto'
        }}>
          <button 
            onClick={onClose}
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
              padding: '5px'
            }}
          >
            ×
          </button>
          <div 
  style={{ 
    marginTop: '20px', 
    color: 'black', 
    fontSize: '15px', // Adjust the text size
    lineHeight: '1.7' // Adjust the line spacing
  }}
>
  <ReactMarkdown components={renderers}>{streamingContent}</ReactMarkdown>
</div>
        </div>
      </div>
    );
  };
  

const Insights = () => {
//   const names = ['vira', 'niko', 'demo'];
  const topics_dict = {
    'Unveiling Our Personality Dynamics': 'Personality Insights',
    'Syncing Our Thought Processes':'Processing Insights', 
    'Deciding Together Seamlessly':'Decision Making Insights',
    'Transforming Conflict into Harmony':'Conflict Management Insights', 
    'What Ignites Our Passion':'Motivation Insights', 
    "Fulfilling Each Other's Desires":'Fundamental Needs Insights'

   
}

  const topics=[
      'Unveiling Our Personality Dynamics',
      'Syncing Our Thought Processes',
      'Deciding Together Seamlessly',
      'Transforming Conflict into Harmony',
      'What Ignites Our Passion',
      "Fulfilling Each Other's Desires"



  ]
  const { user } = useUser();
    const location = useLocation();
  const names = location.state?.insightsData;
    const navigate = useNavigate();

  const [selectedName, setSelectedName] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [userPressure, setUserPressure] = useState(false);
  const [colleaguePressure, setColleaguePressure] = useState(false);
  const [responsePopup, setResponsePopup] = useState(null);
  const [streamingContent, setStreamingContent] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
   const { selectedBeliefSystem ,setSelectedBeliefSystem} = useUser();

  const containerStyle = {
    padding: '40px',
    backgroundColor: '#000000',
    minHeight: '100vh',
    color: 'white',
    fontFamily: 'Arial, sans-serif'
  };
  const beliefSystemContainerStyle = {
    marginTop:'60px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px'
  };

  const titleStyle = {
    fontSize: '2.5rem',
    textAlign: 'center',
    marginBottom: '20px'
  };

  const nameContainerStyle = {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    marginBottom: '30px',
    justifyContent: 'center'
  };

  const nameButtonStyle = (isSelected) => ({
    padding: '10px 30px',
    backgroundColor: isSelected ? '#5bacb9' : 'transparent',
    border: '1px solid #5bacb9',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  });

  const topicContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '40px',
    justifyContent: 'center'
  };

  const topicButtonStyle = (isSelected) => ({
    padding: '12px 25px',
    backgroundColor: isSelected ? '#5bacb9' : 'transparent',
    border: '1px solid #5bacb9',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
  });

  const toggleContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
  };

  const toggleLabelStyle = {
    marginRight: '10px',
    fontSize: '16px',
  };

  const toggleButtonStyle = {
    position: 'relative',
    width: '60px',
    height: '30px',
    backgroundColor: '#ccc',
    borderRadius: '15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  const toggleButtonActiveStyle = {
    ...toggleButtonStyle,
    backgroundColor: '#69b3c1',
  };

  const toggleSwitchStyle = (isActive) => ({
    position: 'absolute',
    top: '2px',
    left: isActive ? '30px' : '2px',
    width: '26px',
    height: '26px',
    borderRadius: '50%',
    backgroundColor: 'white',
    transition: 'left 0.3s',
  });

  const proceedButtonStyle = {
    padding: '13px 35px',
    backgroundColor: (selectedName && selectedTopic) ? '#5bacb9' : '#549fac',
    border: 'none',
    borderRadius: '5px',
    color: 'white',
    cursor: (selectedName && selectedTopic) ? 'pointer' : 'not-allowed',
    fontSize: '1.1rem',
    display: 'block',
    margin: '40px auto'
  };

  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

  const modalContentStyle = {
    position: 'relative',
    backgroundColor: 'black',
    padding: '60px',
    borderRadius: '10px',
    maxWidth: '80vw',
    maxHeight: '80vh',
    overflow: 'auto',
    color: 'white',
    border: '2px solid #5bacb9'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '5px',
    color: 'white',
    fontSize: '24px'
  };

  const getModuleName = (topic) => {
    const ans=topics_dict[topic]
    return ans.toLowerCase().replace(' insights', ' module');
};
  const handleSubmit = async () => {
    if (!selectedName || !selectedTopic) {
      alert('Please select a name and a topic');
      return;
    }
    setStreamingContent('');
    setIsPopupOpen(true); 

    try {
      const response = await fetch('https://lab-api.aris.ai/ARIS/relationship-insight', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name: user,
          partner_name: selectedName,
          module_name: getModuleName(selectedTopic),
          u1_Pstatus: userPressure,
          u2_Pstatus: colleaguePressure,
          stream: true,
          belief_system:selectedBeliefSystem||{}
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const text = decoder.decode(value);
        setStreamingContent(prevContent => prevContent + text);
      }

    } catch (error) {
      console.error('Error:', error);
      
    }
  };

  const closePopup = () => {
    setResponsePopup(null);
  };
  const handleBackButtonClick = () => {
        
       
    navigate('/homepage');
  
  
 
};


  return (
    <div style={containerStyle}>
      
      <h1 style={titleStyle}>Insights</h1>
      <button
          size={30} 
          color="#6cb4c2" 
          onClick={handleBackButtonClick} 
          style={{ position:'absolute',top:'20px',cursor: 'pointer' ,width:'50px',height:'27px',backgroundColor:'black',color:'#69b3c1',border:'1.2px solid #69b3c1',borderRadius:'5px'}}
        >Back</button>
    
      <div style={beliefSystemContainerStyle}>
        <BeliefSystem/>
      </div>
      
      <h2 style={{...titleStyle, fontSize: '1.5rem', marginBottom: '20px'}}>Select a Name</h2>
      <div style={nameContainerStyle}>
        {names.map((name) => (
          <button
            key={name}
            style={nameButtonStyle(selectedName === name)}
            onClick={() => setSelectedName(name)}
          >
            {name}
          </button>
        ))}
      </div>

      {selectedName && (
        <>
          <h2 style={{...titleStyle, fontSize: '1.5rem', marginBottom: '20px'}}>Choose a Topic</h2>
          <div style={topicContainerStyle}>
            {topics.map((topic) => (
              <button
                key={topic}
                style={topicButtonStyle(selectedTopic === topic)}
                onClick={() => setSelectedTopic(topic)}
              >
              { topic}
              </button>
            ))}
          </div>
        </>
      )}

      {selectedName && selectedTopic && (
        <div style={toggleContainerStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={toggleLabelStyle}>You are under pressure</span>
            <div 
              style={userPressure ? toggleButtonActiveStyle : toggleButtonStyle} 
              onClick={() => setUserPressure(!userPressure)}
            >
              <div style={toggleSwitchStyle(userPressure)}></div>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={toggleLabelStyle}>{selectedName} under pressure</span>
            <div 
              style={colleaguePressure ? toggleButtonActiveStyle : toggleButtonStyle} 
              onClick={() => setColleaguePressure(!colleaguePressure)}
            >
              <div style={toggleSwitchStyle(colleaguePressure)}></div>
            </div>
          </div>
        </div>
      )}

      {selectedName && selectedTopic && (
        <button 
          style={proceedButtonStyle}
          onClick={handleSubmit}
        >
          Proceed
        </button>
      )}




<ResponsePopup 
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        streamingContent={streamingContent}
      />

      {/* {responsePopup && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <button 
              onClick={closePopup} 
              style={closeButtonStyle}
            >
              ×
            </button>
            <pre style={{color: '#5bacb9'}}>{JSON.stringify(responsePopup, null, 2)}</pre>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Insights;